import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import internalTransaction from "../../../../assets/Beneficiary Icons/Internal_transaction.png";
import errorIcon from "../../../../assets/Beneficiary Icons/error_with_white_outlined_circle.png";
import view_statement from "../../../../assets/OwnerAccount/view_statement.svg";
import Refreshbalancecard from "../../../../assets/OwnerAccount/refreshbalance.svg";

import assigntag from "../../../../assets/OwnerAccount/assigntag.svg";

import editIcon from "../../../../assets/Beneficiary Icons/edit_white.png";
import arrowRight from "../../../../assets/Accounts/Create/arrowright.png";
import {
  ownerAccDetail,
  addOwnerAccTags,
  removeOwnerAccTags,
  setTags,
  getOwnerAccBalance,
  setshowsuccestoast,
  ownerListViewAllAcc,
  ownerDashboard,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast/index";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import OwnerTransactionHistoryModal from "../../../../components/Accounts/OwnerAccounts/OwnerTransactionHistoryModal";
import OwnerDetailsSelectTag from "../../../../components/Accounts/OwnerAccounts/OwnerDetailsSelectTag";
import OwnerViewStatementModal from "../../../../components/Accounts/OwnerAccounts/OwnerViewStatementModal";
import transactionHistory from "../../../../assets/TxnDetails/new_transaction_history.png";

import { checkUsertoken } from "../../../../redux/features/login/login";
import { Box, Button } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";


function OwnerDetailsPage() {
  const [loading, setLoading] = useState(false);

  const [transactionOpenPop, setTransactionOpenPop] = useState(false);
  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const [selectedTagOpenPop, setSelectedTagOpenPop] = useState(false);

  const [ownerDetailData, setOwnerdetaildata] = useState([]);
  const [accId, setAccId] = useState("");
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showAssignedTags, setShowAssignedTags] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false);
  const [balanceFailed, setBalanceFailed] = useState(false);

  const [newTags, setNewTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);

  const haveName = newTags?.some((val) => val?.type === "ownerTags");

  const {

    ownerAccBalanceLoading,
    ownerAccBalanceError,
    ownerAccountAvailabletags,
    showSuccessToast,

  } = useSelector((state) => state.ownerAllVerification);
  const services = useSelector((state) => state.refreshToken.services);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOwnerDetaildata = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());
      const response = await dispatch(ownerAccDetail(id));

      setOwnerdetaildata(response?.payload?.owner_accounts[0]);

      if (
        response?.payload?.owner_accounts[0]?.tags &&
        response?.payload?.owner_accounts[0]?.tags.length > 0
      ) {
        const data = response?.payload?.owner_accounts[0].tags?.map((res) => {
          return { selected: false, value: res, type: "ownerTags" };
        });

        setNewTags(data);
      }

      setLoading(false);
      if (response?.error?.message == "Request failed with status code 404") {
        navigate("*");
      }
      console.log(response)

      if (response?.payload === undefined) {
        navigate('/feature_not_assigned');
      } else {
        console.log('No user unauthorized error.');
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOwnerDetaildata();
    if (ownerAccountAvailabletags?.length == 0) {
      let availableTags = sessionStorage?.getItem("AvailableTags");
      const parsedTags = JSON?.parse(availableTags, ...newTags);
      dispatch(setTags(parsedTags));
    }
  }, []);

  const handleAddTags = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      let response = await dispatch(
        addOwnerAccTags({ owner_id: [id], tags: selectedTags })
      );

      if (response?.payload?.status === true) {

        getOwnerDetaildata();
        setShowDisplay(false);
        setSelectedTagOpenPop(false);
        setLoading(true);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveTags = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => tag.selected === true)
        .map((tag) => tag.value);

      let response = await dispatch(
        removeOwnerAccTags({ owner_id: [id], tags: selectedTags })
      );

      if (response?.payload?.status == true) {
        getOwnerDetaildata();
        setNewTags(response?.payload.tags?.value);

        setSelectedTagOpenPop(false);
        setShowDisplay(false);

        setLoading(true);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleToastClick = () => {
    toast.info("Currently in Development", toast_position);
  };

  const handleRefresh = async (ownerid) => {
    setPrevConnectedBal(ownerDetailData?.owner_account_balance);
    try {
      dispatch(checkUsertoken());
      setBalanceFailed(false);
      let response = await dispatch(getOwnerAccBalance(ownerid));
      if (response?.payload?.status == true) {
        setRefreshedConnectedId(id);
        setCurrentConnectedBal(
          response?.payload?.owner_accounts[0]?.owner_account_balance
        );

        setShowDisplay(true);

        setOwnerdetaildata((prevState) => {
          return {
            ...prevState,
            owner_account_balance_last_fetched_at_in_words:
              response?.payload?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
            owner_account_balance:
              response?.payload?.owner_accounts[0]?.owner_account_balance,
            owner_account_balance_last_fetched_at:
              response.payload?.owner_accounts[0]
                .owner_account_balance_last_fetched_at,
            difference: Math.abs(
              Number(prevState.owner_account_balance) -
              Number(response.payload.owner_accounts[0].owner_account_balance)
            ),
            increaseOrDecrease:
              Number(prevState.owner_account_balance) >
                Number(response.payload.owner_accounts[0].owner_account_balance)
                ? "decreased"
                : Number(prevState.owner_account_balance) <
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  )
                  ? "increased"
                  : "",
          };
        });
      } else {
        setTimeout(() => {
          toast.dismiss();

          setShowDisplay(true);
          setBalanceFailed(true);
          toast.error(response?.error?.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const handleCloseModal = () => {
    setTransactionOpenPop(false);
    setViewstatementOpenPop(false);
    setSelectedTagOpenPop(false);
  };
  const handleSelectedtag = () => {
    setSelectedTagOpenPop(true);
  };

  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  useEffect(() => {
    if (currentConnectedBal > prevConnectedBal) {
      setIsMore(true);
    } else {
      setIsMore(false);
    }
  }, [currentConnectedBal, prevConnectedBal]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }

    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();

        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  const toggleDetails = () => {
    setShowDetails(true);
    setShowAssignedTags(false);
  }

  const toggleAdditionalDetails = () => {
    setShowAssignedTags(true);
    setShowDetails(false);
  };



  return (
    <>
      <div
        style={{
          height: "100vh",
          overflowX: "hidden",
          overflowY:
            transactionOpenPop || viewStatementOpenPop || selectedTagOpenPop
              ? "hidden"
              : "auto",
        }}
      >
        <div>
          {transactionOpenPop && (
            <div className="fixed top-0 w-full  h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
              {<OwnerTransactionHistoryModal onClose={handleCloseModal} />}
            </div>
          )}
          {viewStatementOpenPop && (
            <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]"  >
              {
                <OwnerViewStatementModal
                  statementId={id}
                  allData={ownerDetailData}
                  onClose={handleCloseModal}
                />
              }
            </div>
          )}
          {selectedTagOpenPop && (
            <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]" onClick={handleCloseModal}>
              {
                <OwnerDetailsSelectTag
                  onClose={handleCloseModal}
                  handleAddTags={handleAddTags}
                  existingTags={existingTags}
                  setExistingTags={setExistingTags}
                  handleRemoveTags={handleRemoveTags}
                  newTags={newTags}
                  setNewTags={setNewTags}
                  ownerExistingTag={ownerDetailData?.tags}
                />
              }
            </div>
          )}
        </div>
        {loading ? (
          <div className="owner-loader-container justify-center items-center flex">
            <img src={dataLoading} alt="" />
          </div>
        ) : (
          <div className="content-page">
            <div className="container">
              <div className="acc-section">
                <div className="major-div">
                  <div className="individual-bank-detail">
                    <div className="individual-account">
                      <span className="individual-account-holdername capitalize">
                        {ownerDetailData?.name?.toLowerCase()}
                      </span>
                      <div className="acc-number-label text-xs">
                        Account holder name
                      </div>
                    </div>
                    <div className="acc-balance">
                      {ownerDetailData?.owner_account_balance ? (
                        <div className="acc-balance-content">
                          <div
                            className="text-sm"
                            style={{
                              fontWeight: "600",
                              color:
                                ownerDetailData?.owner_account_balance >= 0
                                  ? "#4DE659"
                                  : "#ff5860",
                              paddingRight: "20px",
                            }}
                          >
                            ₹{" "}
                            {
                              formatTotalBalance(
                                Number(ownerDetailData?.owner_account_balance)
                              ).amount
                            }
                          </div>

                          <span
                            style={{
                              position: "relative",
                              left: "-15px",
                              marginLeft: "5px",
                              top: "-5px",
                            }}
                          >
                            <AnimationUpAndDown
                              loader={ownerAccBalanceLoading}
                              currentId={refreshedConnectedId}
                              previousId={id}
                              currentBalance={currentConnectedBal}
                              previousBalance={prevConnectedBal}
                              refreshType={"single"}
                              classType={"owner_details"}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="acc-balance-content">
                          <div className={`text-sm`}>N/A</div>
                        </div>
                      )}
                      <div className="acc-number-balance  text-xs">
                        {ownerDetailData?.owner_account_balance ? (
                          formatTotalBalance(
                            Number(ownerDetailData?.owner_account_balance)
                          ).prefix == "crores" ? (
                            <p>
                              Balance - In crores{" "}
                              {(ownerDetailData?.connected_banking === "Y" ||
                                "N") &&
                                ownerDetailData?.active_status !== "Y"
                                ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                                : null}
                            </p>
                          ) : (
                            <p>

                              Balance
                              - updated {ownerDetailData?.owner_account_balance_last_fetched_at_in_words}

                            </p>
                          )
                        ) : (
                          <>
                            <p>
                              Balance{" "}


                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="acc-status-main">
                    <div className="acc-connected">
                      <div
                        className={`text-base ${ownerDetailData?.connected_banking === "Y"
                          ? "active-status"
                          : ownerDetailData?.connected_banking === "N"
                            ? "not-active-status"
                            : ""
                          }`}
                      >
                        {ownerDetailData?.connected_banking === "Y"
                          ? "Active"
                          : "Y" && ownerDetailData?.connected_banking === "N"
                            ? "Inactive"
                            : ""}
                      </div>
                      <div className="acc-number-label">Connected banking</div>
                    </div>

                    {ownerDetailData?.connected_banking == "Y" &&
                      showDisplay &&
                      !balanceFailed ? (
                      <div className="acc-status-detail-page">
                        <div className="acc-status-content">
                          <div
                            className="text-sm"
                            style={{
                              fontWeight: "600",

                              paddingRight: "20px",
                              color:
                                ownerDetailData?.increaseOrDecrease ===
                                  "increased"
                                  ? "#4DE659"
                                  : ownerDetailData?.increaseOrDecrease ===
                                    "decreased"
                                    ? "#FF5860"
                                    : "#F9F9F9",
                            }}
                          >
                            ₹{" "}
                            {
                              formatTotalBalance(ownerDetailData?.difference)
                                .amount
                            }
                          </div>
                          <div className="acc-number-label">
                            Balance{" "}
                            {ownerDetailData?.increaseOrDecrease == ""
                              ? "increased"
                              : ownerDetailData?.increaseOrDecrease}{" "}
                            by{" "}
                            {formatTotalBalance(ownerDetailData?.difference)
                              .prefix == "crores"
                              ? "- in crores"
                              : ""}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="bank-details">
                    <div className="accno-details">
                      <span className="accno-response">
                        {formatAccountNumber(ownerDetailData?.bank_acc_no)}
                      </span>
                      <div className="acc-number-label text-xs">
                        Account number
                      </div>
                    </div>


                    <div className="ifsc-div-main hover:bg-[#667CA3] hover:shadow-lg hover: w-[150px] flex flex-row">
                      <div
                        onClick={() => {
                          if (services?.includes("VIFSC")) {
                            navigate(
                              `/accounts/owner/owner_account_details/IFSC_Details/${ownerDetailData?.ifsc_code}`
                            );
                          } else {
                            //
                          }
                        }}
                        className={`ifsc-div-content   ${services?.includes("VIFSC")
                          ? "cursor-pointer"
                          : "cursor-default"
                          }  `}
                      >
                        <div
                          className={`${services?.includes("VIFSC")
                            ? "h-full w-full p-[2px]"
                            : ""
                            }`}
                        >
                          <div className="ifsc-response">
                            {ownerDetailData?.ifsc_code}
                          </div>
                          <div className="ifsc-code-element text-xs p-[2px]">
                            IFSC code
                          </div>
                        </div>

                        <div className="grid place-content-center w-6 h-6 mt-1 bg-[#1D3A6D] rounded-md">
                          <img
                            src={arrowRight}
                            alt="document"
                            className={`w-[8px] h-[8px] ${services?.includes("VIFSC")
                              ? "cursor-pointer"
                              : "cursor-default"
                              }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="minor-div">
                  <div className="recipient flex flex-row py-5 bg-[#506994]">
                    <div className="recipient-box1 flex-grow  ">
                      <Box
                        className="text-center h-full flex flex-col justify-center gap-1 p-2"
                        sx={{
                          opacity:
                            ownerDetailData?.connected_banking == "Y"
                              ? "1"
                              : "0.5",
                          cursor:
                            ownerDetailData?.connected_banking == "Y"
                              ? "pointer"
                              : "default",
                        }}
                      >
                        <div className="recipient-image-div flex justify-center">
                          <div className="refresh-div">
                            {ownerAccBalanceLoading &&
                              !ownerAccBalanceError &&
                              accId == ownerDetailData?.id ? (
                              <BalanceLoader
                                ownerDetails={"BalanceownerDetails"}
                                loader={ownerAccBalanceLoading}
                              />
                            ) : !ownerAccBalanceLoading &&
                              accId == ownerDetailData?.id ? (
                              <BalanceLoader
                                loader={ownerAccBalanceLoading}
                                setAccId={setAccId}
                                ownerDetails={"BalanceownerDetails"}
                              />
                            ) : (
                              <div
                                className={
                                  ownerDetailData?.connected_banking == "Y"
                                    ? "hover:bg-[#667CA3] w-[102px] h-[90px] mr-2 p-1"
                                    : null
                                }
                              >
                                <Button
                                  disabled={
                                    ownerDetailData?.connected_banking == "N"
                                  }
                                  onClick={() => {
                                    setAccId(ownerDetailData?.id);
                                    handleRefresh(ownerDetailData?.id);
                                  }}
                                  startIcon={
                                    <img
                                      className="refresh-img-icon"
                                      alt="refresh"
                                      src={Refreshbalancecard}
                                    />
                                  }
                                  variant="text"
                                  size="small"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: "10px",
                                    color: "#ffffff ",
                                    textTransform: "none",
                                  }}
                                >
                                  <div className="flex justify-center pr-2 mt-1">
                                    <Box>
                                      <span className=" image-label  font-sans ">
                                        {" "}
                                        Refresh balance{" "}
                                      </span>
                                    </Box>
                                  </div>
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </Box>
                    </div>
                    <div className="recipient-box2 flex-grow  ">
                      <Box
                        className=" opacity-50  text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={handleToastClick}
                      >
                        <div className="recipient-image-div flex justify-center  ">
                          <Box className="flex justify-center w-10">
                            <img
                              src={internalTransaction}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Initiate internal transaction
                          </Box>
                        </div>
                      </Box>
                    </div>
                    <div className=" recipient-box3 flex-grow">
                      <Box
                        className="text-center  opacity-50  h-full flex flex-col justify-center gap-1 p-2"
                        onClick={handleToastClick}
                      >
                        <div className="recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={transactionHistory}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Transaction history
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>

                  <div className="statement flex flex-row  ">
                    <div className="bank-logo-details h-full">
                      <div className="logo-bank-container">
                        <img
                          src={require("../../../../assets/IfscLogo/" +
                            IfscCodeMapper(ownerDetailData?.ifsc_code))}
                          alt="bank"
                          className="detailed-bank-logo"
                        />
                      </div>
                    </div>
                    <div className="border-l-2 border-[#1D3A6D] h-12 mt-5 ml-[1px]"></div>
                    <div className=" recipient-box5  flex-grow">
                      <Box
                        className="text-center h-full flex flex-col justify-center gap-1 p-2 "
                        sx={{
                          opacity:
                            ownerDetailData?.connected_banking == "Y"
                              ? "1"
                              : "0.5",
                          cursor:
                            ownerDetailData?.connected_banking == "Y"
                              ? "pointer"
                              : "default",

                          "&:hover": {
                            backgroundColor:
                              ownerDetailData?.connected_banking === "Y"
                                ? "#667CA3"
                                : "inherit",
                          },
                        }}
                        onClick={() => {
                          if (ownerDetailData?.connected_banking == "Y") {
                            handleViewStatement();
                          }
                        }}
                      >
                        <div className="recipient-image-div  flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={view_statement}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            View account statement
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>

                  <div className="statement-view-acc flex flex-row  bg-[#506994]">
                    <div className="signature-error-div">
                      {ownerDetailData?.connected_banking === "N" ? (
                        <></>
                      ) : ownerDetailData?.connected_banking === "Y" &&
                        balanceFailed &&
                        showDisplay ? (
                        <>
                          <div className="statementNotFoundError flex flex-row justify-center  p-4 gap-2">
                            <div className="flex justify-center items-center">
                              <img
                                src={errorIcon}
                                alt=""
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            </div>
                            <span className="text-[12px] text-white w-36">
                              Currently unable to fetch balance details.Please
                              try again later
                            </span>
                          </div>
                        </>
                      ) : ownerDetailData?.connected_banking === "Y" &&
                        balanceFailed &&
                        showDisplay ? (
                        <>
                          <div className="statementNotFoundError flex flex-row justify-center  p-4 gap-2">
                            <div className="flex justify-center items-center">
                              <img
                                src={errorIcon}
                                alt=""
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            </div>
                            <span className="text-[12px] text-white w-36">
                              Currently unable to fetch balance details.Please
                              try again later
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {ownerDetailData?.connected_banking === "Y" &&
                      balanceFailed &&
                      showDisplay && (
                        <div className="border-l-2 border-[#1D3A6D] h-12 mt-5 ml-[-1px]"></div>
                      )}
                    <div className="recipient-box6 flex-grow hover:bg-[#667CA3]">
                      <Box
                        className="text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={() => handleSelectedtag()}
                      >
                        <div className="recipient-image-div  flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={assigntag}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2  ">
                          <Box>
                            <div className=" image-label h-8/12 self-center antialiased truncate... w-6">
                              {" "}
                              Assign tag{" "}
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-div-section w-screen">
              <div className="details-section">
                <div className="buttonsforOwnerdetails">
                  <button
                    className={`DetailsOwnerButton w-20 h-7 mr-5 ${showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                      }`}
                    onClick={toggleDetails}
                  >
                    <span
                      className={`text-xs text-center font-sans font-semibold ${showDetails ? "text-black" : "text-white"
                        } `}
                    >
                      Details
                    </span>
                  </button>
                  <button
                    className={`AssignedButton w-[7rem] h-7 ${showAssignedTags ? "effective" : "hover:bg-[#A3A3A3]"
                      }`}
                    onClick={toggleAdditionalDetails}
                  >
                    <span
                      className={`text-xs text-center font-sans font-semibold ${showAssignedTags ? "text-black" : "text-white"
                        }`}
                    >
                      Assigned tags
                    </span>
                  </button>
                </div>
                {showDetails ? (
                  <div
                    key={ownerDetailData?.id}
                    className="bank-card-row  gap-10"
                  >
                    <div className="bank-detail-card  w-1/4 ">
                      <h4 className="title1">Bank Details</h4>

                      <div className="w-50 h-50 acc-type-section-div">
                        <div className="data-div">
                          {ownerDetailData?.account_type}
                        </div>
                        <div className="acc-type-div">Account type</div>
                      </div>
                    </div>

                    <div className="support-detail-card w-1/4 mb-[30px]">
                      <div className="w-50 h-50">
                        <h4 className="title1 font-medium">Owner Details</h4>
                        <div className="mt-[5px]">
                          <div className="data-div">
                            {ownerDetailData?.pan_no}
                          </div>
                          <div className="  border-b-2  border-solid border-[#1D3A6D] owner-div">
                            PAN number
                          </div>
                        </div>
                        <div className="mt-[8px]">
                          <div id="email-id-firstLetter" className="data-div">
                            {ownerDetailData?.email_id}
                          </div>
                          <div className="  border-b-2  border-solid border-[#1D3A6D] owner-div">
                            Entity email ID
                          </div>
                        </div>
                      </div>
                      <div className="w-50 h-50">
                        <div
                          className=" flex flex-row mt-2 justify-between 
"
                        >
                          <div id="id-firstLetter" className="data-div">
                            {ownerDetailData?.id}
                          </div>
                        </div>
                        <div className="pb-3  ifsc-code-div">
                          Owner account ID
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="assigned-card-row  gap-10">
                    <div
                      className="assigned-detail-card  w-1/4 flex flex-row flex-wrap"
                      style={{ height: haveName > 0 ? "auto" : "66px" }}
                    >
                      {haveName ? (
                        newTags
                          ?.filter((tag) => tag.type === "ownerTags")
                          ?.map((tag) => {
                            return (
                              <>
                                <Box
                                  className=" rounded-xl"
                                  style={{
                                    height: "26px !important",
                                    text: "12px",
                                    background: "#506994",
                                    margin: "5px 10px 5px 0",
                                    padding: "0px 10px 5px 9px",
                                    color: "#F9F9F9",
                                    width: "auto !important",
                                    cursor: "default",
                                  }}
                                  key={tag?.value}
                                >
                                  <>
                                    <span
                                      id=" id-textTag"
                                      style={{

                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      {tag?.value}
                                    </span>
                                  </>
                                </Box>
                              </>
                            );
                          })
                      ) : (
                        <p className="NotagsAssigned">
                          No tags assigned to this account.
                        </p>
                      )}

                      <div
                        className="editTag"
                        onClick={() => handleSelectedtag()}
                      >
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="edit-icon"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OwnerDetailsPage;
