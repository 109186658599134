import { Box, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import addbutton from "../../../../assets/Accounts/Create/addbutton.png";
import arrowright from "../../../../assets/Accounts/Create/arrowright.png";
import bill from "../../../../assets/Accounts/Create/bill.png";
import Datacard from "../../../../components/Datacard";
import { useDispatch, useSelector } from "react-redux";
import "../Dashboard/accdashboard.css";
// import "./index.css";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { Link, useNavigate } from "react-router-dom";
import { beneDashboard } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import PrevIcon from "../../../../assets/Beneficiary Icons/PreviousIcon.png";
import NextIcon from "../../../../assets/Beneficiary Icons/NextIcon.png";
import createicon from "../../../../assets/newdashboardicon/beneficiary_account_with_blue_circle.svg";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { beneDashboardAccData, beneDashboardAccLoading } = useSelector(
    (state) => state.beneAllVerification
  );
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneDashboard({ page: 1, page_size: 50 }));
  }, [dispatch]);

  const handleDatacardClick = (approvalStatus) => {
    const routeMap = {
      approved: "/accounts/beneficiary_account/view_all",
      pending:
        services.includes("BAAccount") || services?.includes("Admin")
          ? "/accounts/beneficiary_account/approval_required"
          : "/accounts/beneficiary_account/view_all",
      rejected: "/accounts/beneficiary_account/view_all",
      blocked: "/accounts/beneficiary_account/view_all",
      archived: "/accounts/beneficiary_account/view_all",
    };

    navigate(routeMap[approvalStatus], {
      state: { approvalStatus },
    });
  };

  const firstTenAccounts = beneDashboardAccData?.beneficiary_accounts?.slice(
    0,
    10
  );

  const getColorClass = (approvalStatus) => {
    switch (approvalStatus) {
      case "pending":
        return "pendingColor";
      case "rejected":
        return "deniedColor";
      case "approved":
        return "approvedColor";
      case "archived":
        return "archivedColor";
      case "blocked":
        return "blockColor";
      default:
        return "lelfP ";
    }
  };

  const accountTypesSummary =
    beneDashboardAccData && beneDashboardAccData.summary
      ? Object.entries(beneDashboardAccData.summary)
      : [];

  const order = [
    "pending_accounts_count",
    "approved_accounts_count",
    "rejected_accounts_count",
    "blocked_accounts_count",
    "archived_accounts_count",
  ];

  const getBeneDataCardsDetails = (accountType, accountAccount) => {
    let accountTypeName;
    let status;
    let color;

    switch (accountType) {
      case "blocked_accounts_count":
        accountTypeName = `Blocked ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "blocked";
        color = "#FF5860";
        break;
      case "archived_accounts_count":
        accountTypeName = `Archived  ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "archived";
        color = "#B4B4B4";
        break;
      case "pending_accounts_count":
        accountTypeName =
          services?.includes("BAAccount") || services?.includes("Admin")
            ? "Require Approval"
            : "Waiting for Approval";
        status = "pending";
        color =
          services?.includes("BAAccount") || services?.includes("Admin")
            ? "#31E5E5"
            : "#FBC02D";

        break;
      case "rejected_accounts_count":
        accountTypeName = `Rejected  ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "rejected";
        color = "#FF5860";
        break;
      case "approved_accounts_count":
        accountTypeName = `Approved  ${
          accountAccount > 1 ? "Accounts" : "Account"
        }`;
        status = "approved";
        color = "#4DDD37";
        break;
      default:
        accountTypeName = "Unknown";
        status = "unknown";
        color = "#000000";
        break;
    }
    return { accountTypeName, status, color };
  };

  const showDataCardDetailsDiv = (accountTypesSummary) => {
    const pendingAccount = accountTypesSummary.find(
      (el) => el[0] === "pending_accounts_count"
    );

    const updatedAccountSummary = pendingAccount
      ? accountTypesSummary
      : [...accountTypesSummary, ["pending_accounts_count", 0]];

    return updatedAccountSummary
      ?.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color } = getBeneDataCardsDetails(
          accountType,
          accountCount
        );

        return (
          <Datacard
            key={accountType}
            color={color}
            onClick={() => handleDatacardClick(status)}
            text={accountTypeName}
            number={accountCount}
          />
        );
      });
  };

  const [hasScroll, setHasScroll] = useState(false);
  const divRef = useRef(null);

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScroll = () => {
    if (divRef.current) {
      const hasVerticalScroll =
        divRef.current.scrollHeight > divRef.current.clientHeight;
      const hasHorizontalScroll =
        divRef.current.scrollWidth > divRef.current.clientWidth;
      setHasScroll(hasVerticalScroll || hasHorizontalScroll);

      const atStart = divRef.current.scrollLeft === 0;
      const atEnd =
        Math.ceil(divRef.current.scrollLeft + divRef.current.clientWidth) >=
        divRef.current.scrollWidth;
      setIsAtStart(atStart);
      setIsAtEnd(atEnd);
    }
  };

  useEffect(() => {
    checkScroll();
    window.addEventListener("resize", checkScroll);
    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, [accountTypesSummary]);

  const scrollLeft = () => {
    if (divRef.current) {
      divRef.current.scrollBy({ left: -200, behavior: "smooth" });
      checkScroll();
    }
  };

  const scrollRight = () => {
    if (divRef.current) {
      divRef.current.scrollBy({ left: 200, behavior: "smooth" });
      checkScroll();
    }
  };

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div className="col-xl-12">
            <div className="allBenefiDashboard">
              <div id="mobileviewd">
                <div className="allBenefiDashboardheader">
                  <div className="allBenefiDashboardheaderimg">
                    <img src={createicon} alt="" />
                  </div>
                  <div className="allBenefiDashboardheadertext">
                    <div className="headertexttop text-xl">Beneficiary</div>
                    <div className="headertextbottom text-sm">Accounts</div>
                  </div>
                </div>
                <Box
                  className={
                    services?.includes("BUAccount") ||
                    services?.includes("Admin")
                      ? "hover:bg-custom-cardHover"
                      : ""
                  }
                  sx={{
                    boxShadow: "5px 5px 11px #00000033",
                    width: "100%",
                    cursor:
                      services?.includes("BUAccount") ||
                      services?.includes("Admin")
                        ? "cursor"
                        : "default",
                    margin: "0px auto 0px auto",
                    opacity: 1,
                    justifyContent: "center",
                    bgcolor: "#506994",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      opacity:
                        services?.includes("BUAccount") ||
                        services?.includes("Admin")
                          ? "1"
                          : "0.5",
                      cursor:
                        services?.includes("BUAccount") ||
                        services?.includes("Admin")
                          ? "cursor"
                          : "default",
                    }}
                    onClick={() => {
                      // if (
                      //   services?.includes("BUAccount") ||
                      //   services?.includes("Admin")
                      // ) {
                      //   navigate("/accounts/beneficiary_account/create");
                      // }
                      showToast();
                    }}
                  >
                    <div>
                      <img
                        src={addbutton}
                        alt="img add"
                        style={{ width: "25px", margin: "0px auto" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "min-content",
                        textAlign: "center",
                        lineHeight: "17px",
                        margin: "10px auto 0px auto",
                        color: "#ffffff",
                      }}
                    >
                      <p>Create Account</p>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="BeneNavMenu">
                {beneDashboardAccData?.beneficiary_accounts?.length > 0 ? (
                  <div className="gridlayout" ref={divRef}>
                    {showDataCardDetailsDiv(accountTypesSummary)}
                  </div>
                ) : (
                  <div className="gridlayout" ref={divRef}>
                    <Box
                      sx={{
                        opacity: "0.9",
                        height: "111px",
                        cursor: "pointer",
                      }}
                    >
                      <Datacard
                        color={
                          services?.includes("BAAccount") ||
                          services?.includes("Admin")
                            ? "#31E5E5"
                            : "#FBC02D"
                        }
                        onClick={() => handleDatacardClick("pending")}
                        text={
                          services?.includes("BAAccount") ||
                          services?.includes("Admin")
                            ? "Require Approval"
                            : "Waiting for Approval"
                        }
                        number={"0"}
                      />
                    </Box>
                    <Box
                      sx={{
                        opacity: "0.9",
                        height: "111px",
                        cursor: "pointer",
                      }}
                    >
                      <Datacard
                        color={"#4DDD37"}
                        onClick={() => handleDatacardClick("approved")}
                        text={"Approved accounts"}
                        number={"0"}
                      />
                    </Box>
                  </div>
                )}
                {hasScroll && (
                  <>
                    <div
                      style={{
                        width: "95%",
                        maxWidth: "1024px",
                        margin: "auto",
                        padding: "10px 0px",
                      }}
                    >
                      <div className="accBeneTxn_NextAndPrevIcon">
                        <div
                          className="accBeneTxn_CarouPrevIcon"
                          onClick={scrollLeft}
                          disabled={isAtStart}
                        >
                          <img src={PrevIcon} alt="" />
                        </div>
                        <div
                          className="accBeneTxn_CarouNextIcon"
                          onClick={scrollRight}
                          disabled={isAtEnd}
                        >
                          <img src={NextIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <br className="clearfix" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="BenefiMain h-screen">
              <div className="BenefiSectionr">
                <div className="row">
                  <div className="col-xl-8 col-md-8 col-sm-12 col-xs-12 ">
                    <div className="BeneAcountleftSecr">
                      {beneDashboardAccLoading ? (
                        <div className="loader-container">
                          <img
                            src={xpentraloader}
                            alt="Loading..."
                            style={{
                              margin: "40% 43% auto",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      ) : beneDashboardAccData?.beneficiary_accounts?.length ? (
                        <>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="recentSectionr">
                                <div className="recenttext">Recent</div>
                                <div
                                  className="viewall hover:bg-custom-cardHover"
                                  onClick={() =>
                                    navigate(
                                      "/accounts/beneficiary_account/view_all",
                                      {
                                        state: { approvalStatus: "all" },
                                      }
                                    )
                                  }
                                >
                                  <p>View All</p>
                                  <img src={arrowright} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="benGridLaySectionr">
                                <div className="row">
                                  {firstTenAccounts?.map((account, index) => (
                                    <div
                                      className={`col-xl-${
                                        firstTenAccounts.length === 1 ? 12 : 6
                                      } col-md-12 col-sm-12 col-xs-12`}
                                    >
                                      <div
                                        className="griditem hover:bg-custom-cardHover"
                                        key={index}
                                      >
                                        <Link
                                          to={`/accounts/beneficiary_account/details/${account.id}`}
                                        >
                                          <div className="itemcontent1">
                                            <div className="textleft1">
                                              <p
                                                className={`lelfP ${getColorClass(
                                                  account.approval_status
                                                )}`}
                                              >
                                                {account.name}
                                              </p>
                                            </div>
                                            <div className="imageright1">
                                              <img
                                                src={require("../../../../assets/IfscLogo/" +
                                                  IfscCodeMapper(
                                                    account.ifsc_code
                                                  ))}
                                                alt="bank"
                                              />
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="beneNotFoundr">
                            <Box
                              sx={{
                                width: "53%",
                                margin: "auto",
                                marginTop: "5%",
                              }}
                            >
                              <img
                                width={"100%"}
                                src={NoDataImg}
                                alt="NoDataImg"
                              />
                              <Typography
                                className="pt-1"
                                sx={{
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                No accounts found <br />
                                All beneficiary accounts will appear here.
                              </Typography>
                            </Box>
                          </div>
                        </>
                      )}
                      <div id="mobileviewd">
                        <Box
                          sx={{
                            boxShadow: "5px 5px 11px #00000033",
                            width: "95%",
                            maxWidth: "654px",
                            margin: "30px auto",
                            paddingBottom: "2%",
                            height: "102px",
                            opacity: 1,
                            borderRadius: "22px",
                            justifyContent: "center",
                            bgcolor: "#3A5686",
                          }}
                        >
                          <div className="QuickActionSec">
                            <div
                              className="leftBenfContent"
                              style={{ maxWidth: "654px" }}
                            >
                              <p>Quick actions</p>
                            </div>

                            <div className="RightBenfContent">
                              <div className="leftSidecontent">
                                <img src={bill} alt="" />
                                <p>Send MIS report</p>
                              </div>
                              <div className="RightSideContent">
                                <img src={arrowright} alt="" />
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div
                    id="webviewd"
                    className="col-xl-4 col-md-4 col-sm-12 col-xs-12"
                  >
                    <div className="BeneAcountRightSecr">
                      <Box
                        className={
                          services?.includes("BUAccount") ||
                          services?.includes("Admin")
                            ? "hover:bg-custom-cardHover"
                            : ""
                        }
                        sx={{
                          boxShadow: "5px 5px 11px #00000033",
                          width: "100%",
                          maxWidth: "308px",
                          cursor:
                            services?.includes("BUAccount") ||
                            services?.includes("Admin")
                              ? "cursor"
                              : "default",
                          margin: "30px auto",
                          opacity: 1,
                          height: "65px",
                          borderRadius: "22px",
                          justifyContent: "center",
                          bgcolor: "#3A5686",
                        }}
                      >
                        <div
                          className="createAc"
                          style={{
                            opacity:
                              services?.includes("BUAccount") ||
                              services?.includes("Admin")
                                ? "1"
                                : "0.5",
                            cursor:
                              services?.includes("BUAccount") ||
                              services?.includes("Admin")
                                ? "cursor"
                                : "default",
                          }}
                          onClick={() => {
                            const requiredService = "BUAccount";
                            if (
                              services?.includes(requiredService) ||
                              services?.includes("Admin")
                            ) {
                              navigate("/accounts/beneficiary_account/create");
                            } else {
                              navigate("/feature_not_assigned", {
                                state: { unavailableService: requiredService },
                              });
                            }
                          }}
                        >
                          <img src={addbutton} alt="img add" />
                          <p>Create Account</p>
                        </div>
                      </Box>

                      <Box
                        sx={{
                          boxShadow: "5px 5px 11px #00000033",
                          width: "100%",
                          maxWidth: "308px",
                          margin: "30px auto",
                          paddingBottom: "2%",
                          height: "102px",
                          opacity: 1,
                          borderRadius: "22px",
                          justifyContent: "center",
                          bgcolor: "#3A5686",
                        }}
                      >
                        <div className="QuickActionSec">
                          <div className="leftBenfContent">
                            <p>Quick actions</p>
                          </div>

                          <div className="RightBenfContent">
                            <div className="leftSidecontent">
                              <img src={bill} alt="" />
                              <p>Send MIS report</p>
                            </div>
                            <div className="RightSideContent">
                              <img src={arrowright} alt="" />
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
