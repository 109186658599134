import React, { useState } from "react";
import "./index.css";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import "./index.css";
import BeneCardLogo from "../../BeneficiaryAccount/BeneCardLogo";
import Refreshicon from "../../../../assets/OwnerAccount/Refresh balance - card.svg";
import BalanceErrorIcon from "../../../../assets/OwnerAccount/balance_error_icon.png";
import Info from "../../../../assets/OwnerAccount/view statement - card.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";
import { useNavigate } from "react-router-dom";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import { useSelector, useDispatch } from "react-redux";
import { setStoredPage } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown/index";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import OwnerViewStatementModal from "../../../../components/Accounts/OwnerAccounts/OwnerViewStatementModal";

const OwnerViewAllCard = ({
  data,
  handleRefresh,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setId,
  total_balance,
  setPrev_Total_balance_state,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  splitView,
  postiveBalanceData,
  negativeBalanceData,
  refreshAll,
}) => {
  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const [accId, setAccId] = useState("");
  const [statementId, setStatementId] = useState("");
  const [accData, setAccData] = useState("");
  const [prevId, setPrevId] = useState("");
  const [prevBalance, setPrevBalance] = useState("");
  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const owneralldata = data;

  const handleClick = (e, id) => {
    if (!isopen) {
      e.stopPropagation();
      handleRefresh(id);
    }
  };

  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  const handleCloseModal = () => {
    setViewstatementOpenPop(false);
  };

  return (
    <>
      {viewStatementOpenPop && (
        <div className="fixed left-0 w-full top-0 h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
          {
            <OwnerViewStatementModal
              statementId={statementId}
              allData={accData}
              onClose={handleCloseModal}
            />
          }
        </div>
      )}
      {splitView ? (
        <div className="w-[1100px] mx-auto flex flex-col px-[40px]">
          <div className="split_view_header">
            <div className="w-[50%]">
              <p>Positive Balance</p>
            </div>
            <div className="w-[50%] pl-[32px]">
              <p>Negative Balance</p>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-[50%] flex flex-wrap animation_test">
              {postiveBalanceData.length > 0 ? (
                <div className="flex flex-wrap gap-[10px]">
                  {postiveBalanceData?.map((account, index) => (
                    <div
                      key={account.id}
                      style={{
                        position: "relative",
                        width: "238px",
                        height: "103px",
                      }}
                    >
                      <Box
                        key={index}
                        className=" hover:bg-[#506994] ownerSelectownerCard relative"
                        onClick={async () => {
                          let selection = window.getSelection();
                          if (selection.toString().length === 0) {
                            if (!isopen) {
                              await dispatch(setStoredPage(page));
                              navigate(
                                `/accounts/owner/owner_account_details/${account.id}`
                              );
                            }
                          }
                        }}
                      >
                        <div
                          className="owner_viewall-details"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "",
                          }}
                        >
                          <div className="views-all-user">
                            {account?.owner_account_balance ? (
                              <div className="balance-container w-full flex justify-between">
                                <div
                                  style={{
                                    color:
                                      account.owner_account_balance < 0
                                        ? "#FF5860"
                                        : "#4DDD37",
                                  }}
                                  className="viewall-bank-acc-div"
                                >
                                  ₹{" "}
                                  {
                                    formatTotalBalance(
                                      account.owner_account_balance,
                                      currency
                                    ).amount
                                  }
                                </div>

                                <span
                                  style={{
                                    position: "relative",
                                    marginLeft: "70px",
                                    marginTop: "-8px",
                                  }}
                                >
                                  {failedRefreshId.includes(account.id) ? (
                                    <div
                                      style={{
                                        marginTop: "8px",
                                        marginLeft: "15px",
                                        position: "relative",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "19px",
                                          height: "19px",
                                          objectFit: "cover",
                                        }}
                                        src={BalanceErrorIcon}
                                        alt="Error"
                                      />
                                    </div>
                                  ) : (
                                    <AnimationUpAndDown
                                      loader={ownerAccBalanceLoading}
                                      currentId={account?.id}
                                      previousId={
                                        refreshType == false
                                          ? false
                                          : refreshType == "single"
                                          ? prevId
                                          : true
                                      }
                                      currentBalance={
                                        account?.owner_account_balance
                                      }
                                      previousBalance={prevBalance}
                                      refreshType={refreshType}
                                      allPrevData={
                                        refreshAllData[index]?.prevbalance
                                      }
                                    />
                                  )}
                                </span>
                                {account.connected_banking == "Y" && (
                                  <div className="card_balance_last_div">
                                    <div className="card_refresh">
                                      {ownerAccBalanceLoading &&
                                      accId == account.id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                        />
                                      ) : !ownerAccBalanceLoading &&
                                        !ownerAccBalanceError &&
                                        accId == account.id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                          setAccId={setAccId}
                                        />
                                      ) : (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title="Refresh"
                                        >
                                          <div
                                            onClick={(e) => {
                                              if (!isopen && !refreshAll) {
                                                setFailedRefreshId([]);
                                                setSuccessRefreshId([]);
                                                setAccId(account.id);
                                                setRefreshtype("single");
                                                handleClick(e, account.id);
                                                setPrevId(account.id);
                                                setId(true);
                                                setPrevBalance(
                                                  account.owner_account_balance
                                                );
                                                setPrev_Total_balance_state(
                                                  total_balance
                                                );
                                              } else {
                                                e.stopPropagation();
                                              }
                                            }}
                                          >
                                            <img
                                              className="card_refresh-img"
                                              alt="refresh"
                                              src={Refreshicon}
                                            />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                    <div
                                      onClick={(e) => {
                                        if (
                                          !isopen &&
                                          account.connected_banking === "Y"
                                        ) {
                                          e.stopPropagation();
                                          setAccData(account);
                                          setStatementId(account.id);
                                          handleViewStatement();
                                        }
                                      }}
                                      className="card_info-div"
                                    >
                                      {account.connected_banking === "Y" && (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                    position: "absolute",
                                                    top: "-8px",
                                                    left: "-10px",
                                                    zIndex: "214379",
                                                  },
                                                position: "absolute",
                                                top: "-8px",
                                                zIndex: "214379",
                                                left: "-10px",
                                              },
                                            },
                                          }}
                                          title="View statement"
                                        >
                                          <img src={Info} alt="Info" />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="balance-container w-full">
                                <div className="not-available">
                                  Not Available
                                </div>
                              </div>
                            )}

                            <div className="viewall-date-div">
                              {account?.owner_account_balance ? (
                                formatTotalBalance(
                                  account.owner_account_balance,
                                  currency
                                ).prefix == "" ? (
                                  <p>Balance</p>
                                ) : (
                                  <p>
                                    Balance - in{" "}
                                    {
                                      formatTotalBalance(
                                        account.owner_account_balance,
                                        currency
                                      ).prefix
                                    }
                                  </p>
                                )
                              ) : (
                                <>
                                  <p>Balance</p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="viewall-balance-div flex justify-between">
                          <div>
                            <p>{formatAccountNumber(account.bank_acc_no)}</p>
                            <p className="account_text">Account number</p>
                          </div>
                          <div className="view-all-card-container  mb-0 pt-1">
                            <BeneCardLogo data={account} owner={true} />
                          </div>
                        </div>
                        <div className="card_onhover">
                          <div className="viewall-balance-div ">
                            <p className="w-[238px] marquee overflow-hidden whitespace-nowrap overflow-ellipsi">
                              <p>{account.name}</p>
                            </p>
                            <p className="account_text">Account name</p>
                          </div>
                          <div className="viewall-balance-div ">
                            {successRefreshId.includes(account.id) ? (
                              <>
                                <p
                                  style={{
                                    color:
                                      account.increaseOrDecrease === "increased"
                                        ? "#4DE659"
                                        : account.increaseOrDecrease ===
                                          "decreased"
                                        ? "#FF5860"
                                        : "#F9F9F9",
                                  }}
                                >
                                  {
                                    formatTotalBalance(
                                      account.difference,
                                      currency
                                    ).amount
                                  }
                                </p>
                                <p className="account_text">
                                  Balance{" "}
                                  {account.increaseOrDecrease == ""
                                    ? "increased"
                                    : account.increaseOrDecrease}{" "}
                                  by{" "}
                                  {formatTotalBalance(
                                    account.difference,
                                    currency
                                  ).prefix == ""
                                    ? ""
                                    : "- in " +
                                      formatTotalBalance(
                                        account.difference,
                                        currency
                                      ).prefix}
                                </p>
                              </>
                            ) : (
                              <>
                                <p>{account.ifsc_code}</p>
                                <p className="account_text">IFSC code</p>
                              </>
                            )}
                          </div>
                          <div className="viewall-balance-div ">
                            <p>
                              {failedRefreshId.includes(account.id) ? (
                                <p
                                  style={{
                                    textWrap: "wrap",
                                    color: "#FF7F33",
                                  }}
                                  className="w-[200px]"
                                >
                                  Currently unable to fetch balance details.
                                  Please try again later.
                                </p>
                              ) : account.owner_account_balance_last_fetched_at_in_words ? (
                                account.owner_account_balance_last_fetched_at_in_words.toLowerCase()
                              ) : (
                                "Not applicable"
                              )}
                            </p>
                            <p className="account_text">Balance updated</p>
                          </div>
                        </div>
                      </Box>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="noaccountfound">
                  No accounts were found with a positive balance.
                </p>
              )}
            </div>
            <div className="owner_split_div"></div>
            <div className="w-[50%] pl-[10px] animation_test2">
              {negativeBalanceData.length > 0 ? (
                <div className="flex flex-wrap gap-[10px]">
                  {negativeBalanceData?.map((account, index) => (
                    <div
                      key={account.id}
                      style={{
                        position: "relative",
                        width: "238px",
                        height: "103px",
                      }}
                    >
                      <Box
                        key={index}
                        className=" hover:bg-[#506994] ownerSelectownerCard relative"
                        onClick={async () => {
                          let selection = window.getSelection();
                          if (selection.toString().length === 0) {
                            if (!isopen) {
                              await dispatch(setStoredPage(page));
                              navigate(
                                `/accounts/owner/owner_account_details/${account.id}`
                              );
                            }
                          }
                        }}
                      >
                        <div
                          className="owner_viewall-details"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "",
                          }}
                        >
                          <div className="views-all-user">
                            {account?.owner_account_balance ? (
                              <div className="balance-container w-full flex justify-between">
                                <div
                                  style={{
                                    color:
                                      account.owner_account_balance < 0
                                        ? "#FF5860"
                                        : "#4DDD37",
                                  }}
                                  className="viewall-bank-acc-div"
                                >
                                  ₹{" "}
                                  {
                                    formatTotalBalance(
                                      account.owner_account_balance,
                                      currency
                                    ).amount
                                  }
                                </div>

                                <span
                                  style={{
                                    position: "relative",
                                    marginLeft: "70px",
                                    marginTop: "-8px",
                                  }}
                                >
                                  {failedRefreshId.includes(account.id) ? (
                                    <div
                                      style={{
                                        marginTop: "8px",
                                        marginLeft: "15px",
                                        position: "relative",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "19px",
                                          height: "19px",
                                          objectFit: "cover",
                                        }}
                                        src={BalanceErrorIcon}
                                        alt="Error"
                                      />
                                    </div>
                                  ) : (
                                    <AnimationUpAndDown
                                      loader={ownerAccBalanceLoading}
                                      currentId={account?.id}
                                      previousId={
                                        refreshType == false
                                          ? false
                                          : refreshType == "single"
                                          ? prevId
                                          : true
                                      }
                                      currentBalance={
                                        account?.owner_account_balance
                                      }
                                      previousBalance={prevBalance}
                                      refreshType={refreshType}
                                      allPrevData={
                                        refreshAllData[index]?.prevbalance
                                      }
                                    />
                                  )}
                                </span>
                                {account.connected_banking == "Y" && (
                                  <div className="card_balance_last_div">
                                    <div className="card_refresh">
                                      {ownerAccBalanceLoading &&
                                      accId == account.id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                        />
                                      ) : !ownerAccBalanceLoading &&
                                        !ownerAccBalanceError &&
                                        accId == account.id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                          setAccId={setAccId}
                                        />
                                      ) : (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title="Refresh"
                                        >
                                          <div
                                            onClick={(e) => {
                                              if (!isopen && !refreshAll) {
                                                setFailedRefreshId([]);
                                                setSuccessRefreshId([]);
                                                setAccId(account.id);
                                                setRefreshtype("single");
                                                handleClick(e, account.id);
                                                setPrevId(account.id);
                                                setId(true);
                                                setPrevBalance(
                                                  account.owner_account_balance
                                                );
                                                setPrev_Total_balance_state(
                                                  total_balance
                                                );
                                              } else {
                                                e.stopPropagation();
                                              }
                                            }}
                                          >
                                            <img
                                              className="card_refresh-img"
                                              alt="refresh"
                                              src={Refreshicon}
                                            />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                    <div
                                      onClick={(e) => {
                                        if (
                                          !isopen &&
                                          account.connected_banking === "Y"
                                        ) {
                                          e.stopPropagation();
                                          setAccData(account);
                                          setStatementId(account.id);
                                          handleViewStatement();
                                        }
                                      }}
                                      className="card_info-div"
                                    >
                                      {account.connected_banking === "Y" && (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                    position: "absolute",
                                                    top: "-8px",
                                                    left: "-10px",
                                                    zIndex: "214379",
                                                  },
                                                position: "absolute",
                                                top: "-8px",
                                                zIndex: "214379",
                                                left: "-10px",
                                              },
                                            },
                                          }}
                                          title="View statement"
                                        >
                                          <img src={Info} alt="Info" />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="balance-container w-full">
                                <div className="not-available">
                                  Not Available
                                </div>
                              </div>
                            )}

                            <div className="viewall-date-div">
                              {account?.owner_account_balance ? (
                                formatTotalBalance(
                                  account.owner_account_balance,
                                  currency
                                ).prefix == "" ? (
                                  <p>Balance</p>
                                ) : (
                                  <p>
                                    Balance - in{" "}
                                    {
                                      formatTotalBalance(
                                        account.owner_account_balance,
                                        currency
                                      ).prefix
                                    }
                                  </p>
                                )
                              ) : (
                                <>
                                  <p>Balance</p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="viewall-balance-div flex justify-between">
                          <div>
                            <p>{formatAccountNumber(account.bank_acc_no)}</p>
                            <p className="account_text">Account number</p>
                          </div>
                          <div className="view-all-card-container  mb-0 pt-1">
                            <BeneCardLogo data={account} owner={true} />
                          </div>
                        </div>
                        <div className="card_onhover">
                          <div className="viewall-balance-div ">
                            <p className="w-[238px] marquee overflow-hidden whitespace-nowrap overflow-ellipsi">
                              <p>{account.name}</p>
                            </p>
                            <p className="account_text">Account name</p>
                          </div>
                          <div className="viewall-balance-div ">
                            {successRefreshId.includes(account.id) ? (
                              <>
                                <p
                                  style={{
                                    color:
                                      account.increaseOrDecrease === "increased"
                                        ? "#4DE659"
                                        : account.increaseOrDecrease ===
                                          "decreased"
                                        ? "#FF5860"
                                        : "#F9F9F9",
                                  }}
                                >
                                  {
                                    formatTotalBalance(
                                      account.difference,
                                      currency
                                    ).amount
                                  }
                                </p>
                                <p className="account_text">
                                  Balance{" "}
                                  {account.increaseOrDecrease == ""
                                    ? "increased"
                                    : account.increaseOrDecrease}{" "}
                                  by{" "}
                                  {formatTotalBalance(
                                    account.difference,
                                    currency
                                  ).prefix == ""
                                    ? ""
                                    : "- in " +
                                      formatTotalBalance(
                                        account.difference,
                                        currency
                                      ).prefix}
                                </p>
                              </>
                            ) : (
                              <>
                                <p>{account.ifsc_code}</p>
                                <p className="account_text">IFSC code</p>
                              </>
                            )}
                          </div>
                          <div className="viewall-balance-div ">
                            <p>
                              {failedRefreshId.includes(account.id) ? (
                                <p
                                  style={{
                                    textWrap: "wrap",
                                    color: "#FF7F33",
                                  }}
                                  className="w-[200px]"
                                >
                                  Currently unable to fetch balance details.
                                  Please try again later.
                                </p>
                              ) : account.owner_account_balance_last_fetched_at_in_words ? (
                                account.owner_account_balance_last_fetched_at_in_words.toLowerCase()
                              ) : (
                                "Not applicable"
                              )}
                            </p>
                            <p className="account_text">Balance updated</p>
                          </div>
                        </div>
                      </Box>
                    </div>
                  ))}
                </div>
              ) : (
                <p style={{ paddingLeft: "30px" }} className="noaccountfound">
                  No accounts were found with a negative balance.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="ownerCard gap-[20px] 2.6xl:gap-[20px] 3.1xl:gap-[20px] 3.6xl:gap-[30px] 4.6xl:gap-[30px] 5.6xl:gap-[40px]">
          {owneralldata?.map((account, index) => (
            <div
              key={account.id}
              style={{
                position: "relative",
                width: "238px",
                height: "103px",
              }}
            >
              <Box
                key={index}
                className=" hover:bg-[#506994] ownerSelectownerCard relative"
                onClick={async () => {
                  let selection = window.getSelection();
                  if (selection.toString().length === 0) {
                    if (!isopen) {
                      await dispatch(setStoredPage(page));
                      navigate(
                        `/accounts/owner/owner_account_details/${account.id}`
                      );
                    }
                  }
                }}
              >
                <div
                  className="owner_viewall-details"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "",
                  }}
                >
                  <div className="views-all-user">
                    {account?.owner_account_balance ? (
                      <div className="balance-container w-full flex justify-between">
                        <div
                          style={{
                            color:
                              account.owner_account_balance < 0
                                ? "#FF5860"
                                : "#4DDD37",
                          }}
                          className="viewall-bank-acc-div"
                        >
                          ₹{" "}
                          {
                            formatTotalBalance(
                              account.owner_account_balance,
                              currency
                            ).amount
                          }
                        </div>

                        <span
                          style={{
                            position: "relative",
                            marginLeft: "70px",
                            marginTop: "-8px",
                          }}
                        >
                          {failedRefreshId.includes(account.id) ? (
                            <div
                              style={{
                                marginTop: "8px",
                                marginLeft: "15px",
                                position: "relative",
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <img
                                style={{
                                  width: "19px",
                                  height: "19px",
                                  objectFit: "cover",
                                }}
                                src={BalanceErrorIcon}
                                alt="Error"
                              />
                            </div>
                          ) : (
                            <AnimationUpAndDown
                              loader={ownerAccBalanceLoading}
                              currentId={account?.id}
                              previousId={
                                refreshType == false
                                  ? false
                                  : refreshType == "single"
                                  ? prevId
                                  : true
                              }
                              currentBalance={account?.owner_account_balance}
                              previousBalance={prevBalance}
                              refreshType={refreshType}
                              allPrevData={refreshAllData[index]?.prevbalance}
                            />
                          )}
                        </span>
                        {account.connected_banking == "Y" && (
                          <div className="card_balance_last_div">
                            <div className="card_refresh">
                              {ownerAccBalanceLoading && accId == account.id ? (
                                <BalanceLoader
                                  loader={ownerAccBalanceLoading}
                                />
                              ) : !ownerAccBalanceLoading &&
                                !ownerAccBalanceError &&
                                accId == account.id ? (
                                <BalanceLoader
                                  loader={ownerAccBalanceLoading}
                                  setAccId={setAccId}
                                />
                              ) : (
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title="Refresh"
                                >
                                  <div
                                    onClick={(e) => {
                                      if (!isopen && !refreshAll) {
                                        setFailedRefreshId([]);
                                        setSuccessRefreshId([]);
                                        setAccId(account.id);
                                        setRefreshtype("single");
                                        handleClick(e, account.id);
                                        setPrevId(account.id);
                                        setId(true);
                                        setPrevBalance(
                                          account.owner_account_balance
                                        );
                                        setPrev_Total_balance_state(
                                          total_balance
                                        );
                                      } else {
                                        e.stopPropagation();
                                      }
                                    }}
                                  >
                                    <img
                                      className="card_refresh-img"
                                      alt="refresh"
                                      src={Refreshicon}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                            <div
                              onClick={(e) => {
                                if (
                                  !isopen &&
                                  account.connected_banking === "Y"
                                ) {
                                  e.stopPropagation();
                                  setAccData(account);
                                  setStatementId(account.id);
                                  handleViewStatement();
                                }
                              }}
                              className="card_info-div"
                            >
                              {account.connected_banking === "Y" && (
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                          position: "absolute",
                                          top: "-8px",
                                          left: "-10px",
                                          zIndex: "214379",
                                        },
                                        position: "absolute",
                                        top: "-8px",
                                        zIndex: "214379",
                                        left: "-10px",
                                      },
                                    },
                                  }}
                                  title="View statement"
                                >
                                  <img src={Info} alt="Info" />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="balance-container w-full">
                        <div className="not-available">Not Available</div>
                      </div>
                    )}

                    <div className="viewall-date-div">
                      {account?.owner_account_balance ? (
                        formatTotalBalance(
                          account.owner_account_balance,
                          currency
                        ).prefix == "" ? (
                          <p>Balance</p>
                        ) : (
                          <p>
                            Balance - in{" "}
                            {
                              formatTotalBalance(
                                account.owner_account_balance,
                                currency
                              ).prefix
                            }
                          </p>
                        )
                      ) : (
                        <>
                          <p>Balance</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="viewall-balance-div flex justify-between">
                  <div>
                    <p>{formatAccountNumber(account.bank_acc_no)}</p>
                    <p className="account_text">Account number</p>
                  </div>
                  <div className="view-all-card-container  mb-0 pt-1">
                    <BeneCardLogo data={account} owner={true} />
                  </div>
                </div>
                <div className="card_onhover">
                  <div className="viewall-balance-div ">
                    <p className="w-[238px] marquee overflow-hidden whitespace-nowrap overflow-ellipsi">
                      <p>{account.name}</p>
                    </p>
                    <p className="account_text">Account name</p>
                  </div>
                  <div className="viewall-balance-div ">
                    {successRefreshId.includes(account.id) ? (
                      <>
                        <p
                          style={{
                            color:
                              account.increaseOrDecrease === "increased"
                                ? "#4DE659"
                                : account.increaseOrDecrease === "decreased"
                                ? "#FF5860"
                                : "#F9F9F9",
                          }}
                        >
                          {
                            formatTotalBalance(account.difference, currency)
                              .amount
                          }
                        </p>
                        <p className="account_text">
                          Balance{" "}
                          {account.increaseOrDecrease == ""
                            ? "increased"
                            : account.increaseOrDecrease}{" "}
                          by{" "}
                          {formatTotalBalance(account.difference, currency)
                            .prefix == ""
                            ? ""
                            : "- in " +
                              formatTotalBalance(account.difference, currency)
                                .prefix}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{account.ifsc_code}</p>
                        <p className="account_text">IFSC code</p>
                      </>
                    )}
                  </div>
                  <div className="viewall-balance-div ">
                    <p style={{ textTransform: "lowercase !important" }}>
                      {failedRefreshId.includes(account.id) ? (
                        <p
                          style={{
                            textWrap: "wrap",
                            color: "#FF7F33",
                          }}
                          className="w-[200px]"
                        >
                          Currently unable to fetch balance details. Please try
                          again later.
                        </p>
                      ) : account.owner_account_balance_last_fetched_at_in_words ? (
                        account.owner_account_balance_last_fetched_at_in_words.toLowerCase()
                      ) : (
                        "Not applicable"
                      )}
                    </p>
                    <p className="account_text">Balance updated</p>
                  </div>
                </div>
              </Box>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default OwnerViewAllCard;
