import React from "react";
import "./index.css";
import { Box } from "@mui/material";
import "./index.css";
import BeneCardLogo from "../BeneCardLogo";
import { useNavigate } from "react-router-dom";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";

const BeneViewAllCard = ({ data, isSidebarOpen }) => {
  const navigate = useNavigate();
  const benealldata = data;
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };
  return (
    <div
      style={{
        overflowY: isSidebarOpen ? "hidden" : "",
        cursor: isSidebarOpen ? "default" : "pointer",
      }}
      className={`beneCard ${isSidebarOpen ? "sidebar-open" : ""}`}
    >
      {benealldata?.map((account, index) => (
        <div className="wrap_top_acc_view">
          <Box
            key={index}
            className="multiSelectBeneCard relative 5.6xl:w-[265px] 3.1xl:w-[264px]"
            style={{
              backgroundColor: "#3a5686",
              cursor: isSidebarOpen ? "default" : "pointer",
            }}
            onClick={() => {
              if (!isSidebarOpen) {
                navigate(`/accounts/beneficiary_account/details/${account.id}`);
              }
            }}
          >
            <div
              className="viewall-details"
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "",
              // }}
            >
              <div className="bene-view-all-user">
                <div
                  className="bene_viewall_name_div"
                  style={{
                    color:
                      account.approval_status === "denied"
                        ? getStatusColor("rejected")
                        : getStatusColor(account.approval_status),
                    marginLeft: "10px",
                  }}
                >
                  {account.name}
                </div>

                <div
                  className="bene_viewall-bank-acc-div"
                  style={{ marginLeft: "10px" }}
                >
                  {" "}
                  {formatAccountNumber(account.bank_acc_no)}
                </div>
                <div
                  className="viewall-date-div"
                  style={{ marginLeft: "10px" }}
                >
                  Account Number
                </div>
              </div>
              <div className="bene_viewall_label-div">
                {/* <div
                className="bene_viewall_box-status"
                style={{
                  backgroundColor:
                    account.approval_status === "denied"
                      ? getStatusColor("rejected")
                      : getStatusColor(account.approval_status),
                }}
              ></div> */}
                <div
                  className={`bene_viewall_assigned ${
                    account.approval_status === "pending"
                      ? "assigned-pending-box"
                      : account.approval_status === "approved"
                      ? "assigned-approved-box"
                      : account.approval_status === "rejected"
                      ? "assigned-denied-box"
                      : account.approval_status === "blocked"
                      ? "assigned-blocked-box"
                      : account.approval_status === "archived"
                      ? "assigned-archived-box"
                      : ""
                  }`}
                >
                  {account.approval_status === "rejected"
                    ? "Denied"
                    : account.approval_status}
                </div>
              </div>
            </div>
            <div className="view-all-card-container_acc absolute right-0 mb-0 flex flex-col justify-end ">
              <BeneCardLogo data={account} type={"account"} />
            </div>
            <div className="bene_txn_viewall_hover_content_acc">
              <div className="pl-[10px] pt-[5px]">
                <div
                  className="text-sm pb-[4px] first-letter:capitalize"
                  style={{
                    color: getStatusColor(account?.approval_status),
                  }}
                >
                  {account?.approval_status}
                </div>
                <div className="text-[#DADADA] text-[10px] ">
                  Approval Status
                </div>
              </div>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default BeneViewAllCard;
