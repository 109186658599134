import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import BeneBankEdit from "../BeneBankEdit";
import BenePanEditModal from "../../../../components/BeneEditAccount/BenePanEditModel";
import BeneEditStepper from "../../../../components/BeneEditAccount/BeneEditStepper";
import BeneEditConfirm from "../../../../components/BeneEditAccount/BeneEditConfirm";
import { checkUsertoken } from "../../../../redux/features/login/login";

const BeneEditBankAccount = () => {
  const steps = ["Enter bank details", "Confirm and create account"];
  const [bankIsPending, setBankIsPending] = useState(false);
  const [bankLookupNext, setBankLookupNext] = useState(false);
  const [bankIsAchived, setBankIsArchived] = useState(false);
  const [bankIsApproved, setBankIsApproved] = useState(false);
  const [bankSelectedItem, setBankSelectedItem] = useState(null);
  const [bankDisableCreateNext, setBankDisableCreateNext] = useState(false);
  const [bankShutViewAll, setBankShutViewAll] = useState(false);
  const [bankAccountType, setBankAccountType] = useState({ type: "", id: "" });
  const [bankIsDennied, setBankIsDennied] = useState(false);
  const [bankIsRejected, setBankIsRejected] = useState(false);
  const [verifiedBank, setVerifiedBank] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [bankNextButtonEnabled, BenEnableNextButton] = useState(true);
  const [bankShowAction, setBankShowAction] = useState(false);
  const [newBankMessage, setBankNewMessage] = useState(false);
  const [hideBankCreate, setBankHideCreate] = useState(false);
  const [hideBankBlock, setBankHideBlock] = useState(false);
  const [isBankLinkedAccount, setIsBankLinkedAccount] = useState(false);
  const [active, setActive] = useState(false);
  const [openEditBankPop, setOpenEditBankPop] = useState(false);
  const [createNewedit, setCreateNewEdit] = useState(false);
  const scrollIt = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const bankCheck = "^[A-Za-z0-9]+$";
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    bank_account: "",
    ifsc_code: "",
    email_id: "",
    pan_no: "",
    bank_name: "",
    contact_no: "",
    legal_name: "",
    old_bank_account: "",
    old_ifsc_code: "",
    name: "",
    gstin_detail: "",
  });
  useEffect(() => {
    dispatch(checkUsertoken());

    const fetchBankData = async () => {
      let response = await dispatch(viewAccountDetail({ id: id }));
      if (response?.error?.message === "Request failed with status code 404") {
        navigate("*");
      }
    };
    fetchBankData();
  }, [dispatch, id, navigate]);

  const { viewAccountDetailData, viewAccountDetailLoading } = useSelector(
    (state) => state.verify
  );

  const beneBankLookError = useSelector(
    (state) => state.beneAllVerification.beneLookError
  );

  useEffect(() => {}, [viewAccountDetailData]);
  useEffect(() => {
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      setPayload({
        ...payload,
        name: viewAccountDetailData?.beneficiary_accounts[0]?.name,
        bank_account:
          viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no,
        ifsc_code: viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code,
        bank_name: viewAccountDetailData?.beneficiary_accounts[0]?.bank_name,
        pan_no: viewAccountDetailData?.beneficiary_accounts[0]?.pan_no,
        email_id: viewAccountDetailData?.beneficiary_accounts[0]?.email_id,
        contact_no: viewAccountDetailData?.beneficiary_accounts[0]?.contact_no,
        legal_name:
          viewAccountDetailData?.beneficiary_accounts[0]?.pan_legal_name,
        old_bank_account:
          viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no,
        old_ifsc_code:
          viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code,
        gstin_detail:
          viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail,
      });
    }
  }, [viewAccountDetailData]);
  const handleBackBank = () => {
    if (activeStep == 0) {
      if (createNewedit) {
        setOpenEditBankPop(true);
      } else {
        navigate(`/accounts/beneficiary_account/details/${id}`);
      }
    } else {
      setButtonDisabled(true);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIsClicked(true);
      if (scrollIt.current) {
        scrollIt.current.scrollLeft -= 1024;
      }
    }
  };

  const handleNextBank = () => {
    if (activeStep == 1) {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    } else {
      setButtonDisabled(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsClicked(true);
      if (scrollIt.current) {
        scrollIt.current.scrollLeft += 1024;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.target.tagName === "INPUT") {
      if (event.key === "Tab") {
        event.preventDefault();
      }
    } else if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Tab" ||
      event.key === " "
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      className="edit_bank_con_main"
      style={{
        overflowY: openEditBankPop ? "hidden" : "",
        height: openEditBankPop ? "100vh" : "",
      }}
    >
      <div className="main_edit_stepper_div">
        {openEditBankPop && (
          <BenePanEditModal
            message2={"Are you sure you want to leave this page?"}
            message={"Leaving this page will not update the changes you made"}
            id={id}
            close={setOpenEditBankPop}
          />
        )}
        <BeneEditStepper
          type={"multiple"}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setIsClicked={setIsClicked}
          setButtonDisabled={setButtonDisabled}
          bankNextButtonEnabled={bankNextButtonEnabled}
          BenEnableNextButton={BenEnableNextButton}
          handleNextBank={handleNextBank}
          handleBackBank={handleBackBank}
          payload={payload}
          bankID={id}
          beneBankLookError={beneBankLookError}
        />
      </div>
      <div className="edit_bank_details_all" ref={scrollIt}>
        <BeneBankEdit
          bankIsAchived={bankIsAchived}
          handleNextBank={handleNextBank}
          setBankIsArchived={setBankIsArchived}
          setBankIsApproved={setBankIsApproved}
          bankIsApproved={bankIsApproved}
          // handleBankChange={handleBankChange}
          bankSelectedItem={bankSelectedItem}
          setBankSelectedItem={setBankSelectedItem}
          bankLookupNext={bankLookupNext}
          setBankLookupNext={setBankLookupNext}
          setBankIsPending={setBankIsPending}
          bankIsPending={bankIsPending}
          bankIsDennied={bankIsDennied}
          bankIsRejected={bankIsRejected}
          setBankIsDennied={setBankIsDennied}
          setBankIsRejected={setBankIsRejected}
          BenEnableNextButton={BenEnableNextButton}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          payload={payload}
          setPayload={setPayload}
          setBankShutViewAll={setBankShutViewAll}
          bankShutViewAll={bankShutViewAll}
          setBankDisableCreateNext={setBankDisableCreateNext}
          bankDisableCreateNext={bankDisableCreateNext}
          bankAccountType={bankAccountType}
          setBankAccountType={setBankAccountType}
          verifiedBank={verifiedBank}
          active={active}
          setActive={setActive}
          newBankMessage={newBankMessage}
          setBankNewMessage={setBankNewMessage}
          hideBankBlock={hideBankBlock}
          setBankHideBlock={setBankHideBlock}
          hideBankCreate={hideBankCreate}
          setBankHideCreate={setBankHideCreate}
          previousBankID={id}
          isBankLinkedAccount={isBankLinkedAccount}
          createNewedit={createNewedit}
          setCreateNewEdit={setCreateNewEdit}
        />
        <BeneEditConfirm
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          payload={payload}
        />
      </div>
    </div>
  );
};

export default BeneEditBankAccount;
