import React, { useEffect, useRef, useState } from "react";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import "./index.css";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const BeneTxnStatusPopover = ({
  getAccStatus,
  selectedStatus,
  setPage,
  isDistributionSelected,
  isStatusSelected,
  setIsStatusSelected,
  isPopoverOpen,
  setIsPopoverOpen,
}) => {
  const popoverRef = useRef(null);

  const handleClick = () => {
    if (isDistributionSelected) {
      toast.error(
        "Only one status filter can be selected at a time.",
        toast_position
      );
    } else {
      setIsPopoverOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const handleClearStatus = (event) => {
    if (event) event.stopPropagation();
    handleClose();
    getAccStatus(null, "status");
    getAccStatus(null, "approval");
    setIsStatusSelected(false);
    setPage(1);
  };

  const handleStatus = (status, state) => {
    handleClose();

    let statusToSend;

    if (status === "denied") {
      statusToSend = "denied";
    } else if (status === "approved") {
      statusToSend = "approved";
    } else {
      statusToSend = status;
    }
    getAccStatus(statusToSend, "status");

    setPage(1);
  };

  const getIcon = () => {
    let iconSrc = null;
    let iconClickHandler = null;

    if (isPopoverOpen) {
      iconSrc = arrowup;
      iconClickHandler = handleClick;
    } else {
      if (
        !isStatusSelected ||
        selectedStatus === null ||
        selectedStatus === "All"
      ) {
        iconSrc = arrowdown;
        iconClickHandler = handleClick;
      } else {
        iconSrc = clear_filter;
        iconClickHandler =
          selectedStatus !== "All" ? handleClearStatus : undefined;
      }
    }

    return { src: iconSrc, onClick: iconClickHandler };
  };

  const { src: icon, onClick: iconClickHandler } = getIcon();

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "blocked":
      case "rejected":
      case "cancelled":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";

      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      case "all":
        return "#ffff";
      case "settled":
        return "#4ddd37";
      default:
        return "#ffff";
    }
  };

  const formatStatusText = (status) => {
    return `${status[0]?.toUpperCase()}${status?.slice(1)?.toLowerCase()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={popoverRef}
      className="beneTxnViewAllPopover"
      style={{
        opacity: isDistributionSelected ? 0.5 : 1,
        cursor: isDistributionSelected ? "default" : "pointer",
      }}
    >
      <div
        className="benTxnViewall_inner_div"
        style={{
          borderBottom: isPopoverOpen ? "1px solid #1d3a6d" : "none",
          opacity: isDistributionSelected ? 0.5 : 1,
          cursor: isDistributionSelected ? "default" : "pointer",
        }}
        onClick={handleClick}
      >
        <button
          id="bene_txn_popover_section"
          style={{ color: getStatusColor(selectedStatus) }}
        >
          {selectedStatus === "denied"
            ? "Denied"
            : selectedStatus === "approved"
            ? "Approved"
            : selectedStatus === "rejected"
            ? "Rejected"
            : formatStatusText(selectedStatus || "All")}
          {/* <img src={icon} alt="Status Icon" className="status_icon" /> */}
        </button>
        <p className="beneTxnViewAllchild2">Approval Status</p>
        <div
          className="bene_viewall_popover_icon p-[4px]"
          onClick={(event) => {
            if (iconClickHandler) {
              event.stopPropagation();
              iconClickHandler();
            }
          }}
        >
          {icon && (
            <img
              src={icon}
              alt={
                selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
              }
              className={`bene_txn_status_icon ${
                selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
              }`}
            />
          )}
        </div>
      </div>
      <div
        className={`bene-txn-viewall-popover ${
          isPopoverOpen ? "open" : "closed"
        }`}
      >
        {[
          "all",
          "pending",
          "approved",
          "denied",
          "blocked",
          "archived",
          "rejected",
          "abandoned",
          "cancelled",
        ].map((status) => (
          <div
            className={`popover h-[30px] hover:bg-[#3A5686] rounded-[5px] flex items-center justify-between mt-[5px] pl-[14px] ${
              status === "approved"
                ? "awaiting_verification_bene_txn_popover_btn"
                : `${status}_bene_txn_popover_btn`
            }`}
            key={status}
            style={{
              transition: "opacity 0.3s ease-in",
              opacity: isPopoverOpen ? "1" : "0",
              visibility: isPopoverOpen ? "visible" : "hidden",
              color: getStatusColor(status),
              backgroundColor:
                selectedStatus === status && selectedStatus !== "all"
                  ? "#3A5686"
                  : "",
            }}
            onClick={() => handleStatus(status)}
          >
            {status === selectedStatus ||
            (status === "denied" && selectedStatus === "denied") ||
            (status === "approved" && selectedStatus === "approved") ? (
              <>
                {formatStatusText(status)}
                <img
                  src={allChecker}
                  alt="Checkmark"
                  className="all-txn-status-icon mr-1"
                />
              </>
            ) : (
              formatStatusText(status)
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeneTxnStatusPopover;
