import React, { useState, useEffect } from "react";
import successImage from "../../../../assets/NewImg.png";
import createAccount from "../../../../assets/create_new_account.png";
import createTransaction from "../../../../assets/create_transaction.png";
import viewDetails from "../../../../assets/view_details.png";
import dashboard from "../../../../assets/Dashboard.png";
import ContactNumber from "../../../../assets/Beneficiary Icons/Contact_Number.png";
import Email from "../../../../assets/Beneficiary Icons/Email.png";
import { beneSuccessDetail } from "../../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import "./index.css";
import declineImage from "../../../../assets/Beneficiary Icons/reddecline.png";
import checkmark from "../../../../assets/Beneficiary Icons/checkmark.png";
import {
  beneBankLookUp,
  beneWaitingApproved,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import {
  // unachivedAccount,
  unachivedAccountDetail,
  achivedAccountDetail,
  viewAccountDetail,
  beneLookUp,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { toast } from "react-toastify";
import DeclinedPopup from "../BeneAcountApproval/DeclinedPopup";

const BeneCreateSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const services = useSelector((state) => state.refreshToken.services);
  const isSuccessErrorData = useSelector(
    (state) => state.beneCreateAccount.isSuccessErrorData
  );
  const [ViewAccountDetails, SetViewAccountDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [getFreshPanStatus, setGetFreshPanStatus] = useState(false);
  const [getFreshBankStatus, setGetFreshBankStatus] = useState(false);
  const [getCheckerBtnStatus, setGetCheckerBtnStatus] = useState(false);
  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    id: "",
    name: "",
    bank_name: "",
    bank_acc_no: "",
    ifsc_code: "",
    category: "",
    pan: "",
  });

  useEffect(() => {
    if (isSuccessErrorData == "Request failed with status code 404") {
      navigate("*");
    }
  }, [isSuccessErrorData]);
  const [btnToggle, setBtnToggle] = useState({
    accountDtls: true,
    accountPan: false,
    accountBank: false,
  });

  console.log("Testing For pull request");

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending" && val?.id != id;
  });

  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  let haveBankApproved = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let haveBankPending = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending" && val?.id != id;
  });

  useEffect(() => {
    let panStatus = sessionStorage.getItem("Create BeneAcc Fresh PAN");
    let bankStatus = sessionStorage.getItem("Create BeneAcc Fresh Bank");
    let CheckerBtnStatus = sessionStorage.getItem(
      "Create BeneAcc Approve and Decline status"
    );

    setGetFreshPanStatus(JSON.parse(panStatus));
    setGetFreshBankStatus(JSON.parse(bankStatus));
    setGetCheckerBtnStatus(JSON.parse(CheckerBtnStatus));
  }, [sessionStorage]);

  const {
    viewAccountDetailData,
    viewAccountDetailLoading,
    achivedDataDetail,
    unachivedLoadingDetail,
    achivedLoadingDetail,
    unachivedDataDetail,
  } = useSelector((state) => state.verify);

  const handleBtnToggle = (type) => {
    if (type == "accountDtls") {
      setBtnToggle({
        accountDtls: true,
        accountPan: false,
        accountBank: false,
      });
    } else if (type == "accountPan") {
      setBtnToggle({
        accountDtls: false,
        accountPan: true,
        accountBank: false,
      });
    } else if (type == "accountBank") {
      setBtnToggle({
        accountDtls: false,
        accountPan: false,
        accountBank: true,
      });
    } else {
      setBtnToggle({
        accountDtls: true,
        accountPan: false,
        accountBank: false,
      });
    }
  };

  const handleApprovalDetails = async (id) => {
    dispatch(checkUsertoken());
    const response = await dispatch(beneWaitingApproved(id));
    if (response?.payload?.status === false) {
      // const error_msg = response?.payload?.errors?.beneficiary_account[0];
      let error_msg = "";
      if (response?.payload?.errors?.beneficiary_account?.[0]) {
        error_msg = response.payload.errors.beneficiary_account[0];
      } else if (response?.payload?.errors?.bank_account_number?.[0]) {
        error_msg = response.payload.errors.bank_account_number[0];
      } else {
        error_msg = "An unknown error occurred.";
      }
      if (error_msg === "Beneficiary account already approved") {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "approved" : "pending",
              };
            }
            return item;
          }
        );
        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
        await dispatch(viewAccountDetail({ id }));
      } else {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "pending" : "",
                error_msg,
              };
            }
            return item;
          }
        );

        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
        // await dispatch(viewAccountDetail({ id }));
      }
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "approved" : "pending",
            };
          }
          return item;
        }
      );
      SetViewAccountDetails(updatedData);
      dispatch(checkUsertoken());
      await dispatch(viewAccountDetail({ id }));

      toast.success(" Beneficiary Account approved successfully ", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });

      navigate(`/accounts/beneficiary_account/details/${id}`);
      sessionStorage.setItem("Create BeneAcc Approve and Decline status", true);
    }
  };

  const handleDeclinedDetials = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    SetViewAccountDetails(updatedAccounts);
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneSuccessDetail(id));
  }, [dispatch, id]);
  const successDetail = useSelector(
    (state) => state.beneCreateAccount.beneSuccessData
  );

  const viewDetailsHandler = () => {
    navigate(`/accounts/beneficiary_account/details/${id}`);
  };

  const createTxn = () => {
    navigate("/transaction/beneficiary_transaction/create", {
      state: currentAccountDetails,
    });
  };

  const createAccountHandler = () => {
    navigate("/accounts/beneficiary_account/create");
  };

  const dashboardHandler = () => {
    navigate("/accounts/beneficiary/dashboard");
  };

  const beneficiaryAccount = successDetail?.beneficiary_accounts[0] || {};

  useEffect(() => {
    if (beneficiaryAccount) {
      const { id, name, bank_name, bank_acc_no, ifsc_code, category, pan_no } =
        beneficiaryAccount;
      setCurrentAccountDetails({
        id,
        name,
        bank_name,
        bank_acc_no,
        ifsc_code,
        category,
        pan: pan_no,
      });
    }
  }, [beneficiaryAccount]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: beneficiaryAccount.pan_no }));
    dispatch(
      beneBankLookUp({
        ifsc: beneficiaryAccount.ifsc_code,
        bank_account_no: beneficiaryAccount.bank_acc_no,
      })
    );
  }, [
    beneficiaryAccount.pan_no,
    beneficiaryAccount.ifsc_code,
    beneficiaryAccount.bank_acc_no,
  ]);

  return (
    <div className="min-h-screen bg-[#457549]">
      <div className="flex justify-center">
        {showPopup && (
          <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
            <DeclinedPopup
              type="decline account details"
              onClose={handleClosePopup}
              accountId={deniedId}
              id={id}
              allAccounts={ViewAccountDetails}
              updateAllAccounts={updateAllAccounts}
              pageName="Success page decline account"
            />
          </div>
        )}
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 pt-48">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">
                    Beneficiary account created successfully <br /> and awaiting
                    approval.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-32 pb-2">
            <div
              className="flex flex-col mx-5 bg-white rounded-4xl items-center justify-between"
              style={{ height: "580px" }}
            >
              <div className="w-full text-white bg-[#55985C] flex justify-evenly items-center gap-0 toggle-btn-container">
                <div
                  className="toggle-btn-label"
                  style={{
                    width:
                      getFreshPanStatus && getFreshBankStatus
                        ? "130px"
                        : getFreshPanStatus || getFreshBankStatus
                        ? "250px"
                        : "100%",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius:
                      !getFreshPanStatus && !getFreshBankStatus ? "30px" : "",
                    backgroundColor: btnToggle.accountDtls ? "#55985C" : "",
                    cursor: btnToggle.accountDtls ? "default" : "pointer",
                  }}
                  onClick={() => {
                    handleBtnToggle("accountDtls");
                  }}
                >
                  Account Details
                </div>
                <div
                  className="vertical-line-seperater"
                  style={{
                    display: getFreshPanStatus ? "block" : "none",
                  }}
                >
                  &nbsp;
                </div>
                <div
                  className="toggle-btn-label"
                  style={{
                    backgroundColor: btnToggle.accountPan ? "#55985C" : "",
                    width:
                      getFreshPanStatus && getFreshBankStatus
                        ? "169px"
                        : getFreshPanStatus && !getFreshBankStatus
                        ? "250px"
                        : "100%",
                    borderTopRightRadius: !getFreshBankStatus ? "30px" : "",
                    cursor: btnToggle.accountPan ? "default" : "pointer",
                    display: getFreshPanStatus ? "flex" : "none",
                  }}
                  onClick={() => {
                    handleBtnToggle("accountPan");
                  }}
                >
                  <>
                    Accounts
                    <br />
                    <p>Associated to PAN</p>
                  </>
                </div>
                <div
                  className="vertical-line-seperater"
                  style={{
                    display: getFreshBankStatus ? "block" : "none",
                  }}
                >
                  &nbsp;
                </div>
                <div
                  className="toggle-btn-label"
                  style={{
                    backgroundColor: btnToggle.accountBank ? "#55985C" : "",
                    width:
                      getFreshPanStatus && getFreshBankStatus
                        ? "170px"
                        : !getFreshPanStatus && getFreshBankStatus
                        ? "250px"
                        : "100%",
                    borderTopRightRadius: "30px",
                    cursor: btnToggle.accountBank ? "default" : "pointer",
                    display: getFreshBankStatus ? "flex" : "none",
                  }}
                  onClick={() => {
                    handleBtnToggle("accountBank");
                  }}
                >
                  <>
                    Accounts
                    <br />
                    <p>Associated to Bank</p>
                  </>
                </div>
              </div>
              {btnToggle.accountDtls ? (
                <div
                  className="w-full flex flex-col gap-2 items-center justify-center overflow-auto h-80"
                  style={{ height: "360px" }}
                >
                  <div
                    className={`flex flex-col font-bold 
                      ${
                        services?.find(
                          (service) =>
                            service === "BAAccount" || service === "Admin"
                        ) !== undefined && !getCheckerBtnStatus
                          ? "pt-[45%]"
                          : "pt-[15%]"
                      } 
                    px-7`}
                  >
                    <div
                      className="text-base text-[#000000] w-full text-center p-[5px]"
                      style={{
                        marginTop: beneficiaryAccount?.gstin_detail
                          ? "70px"
                          : "",
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        arrow
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                        title={
                          beneficiaryAccount?.name
                            ? beneficiaryAccount?.name?.toLowerCase()
                            : null
                        }
                        placement="bottom-start"
                      >
                        <div
                          className="w-96"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: " nowrap",
                            textTransform: "capitalize",
                          }}
                        >
                          {beneficiaryAccount?.name
                            ? beneficiaryAccount?.name?.toLowerCase()
                            : null}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="w-full content-center">
                    <div className="mx-0 dashedLine"></div>
                  </div>
                  <div className="detailsText">
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                          title={beneficiaryAccount.category}
                          placement="bottom-start"
                        >
                          <div className="font-normal text-base capitalize">
                            <div
                              className="w-96"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textAlign: "start",
                              }}
                            >
                              {typeof beneficiaryAccount.category === "string"
                                ? beneficiaryAccount.category
                                    .split(",")
                                    .map((category) => category.trim())
                                    .join(" , ")
                                : beneficiaryAccount.category}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        Assigned categories
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        {beneficiaryAccount?.pan_no}
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        PAN number
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-col space-y-1 text-left">
                      <div className="text-base text-black antialiased">
                        {formatAccountNumber(beneficiaryAccount?.bank_acc_no)}
                      </div>
                      <div className="text-gray-500 text-xs antialiased">
                        Account number
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <div className="text-base text-black antialiased">
                          {beneficiaryAccount?.ifsc_code}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          IFSC code
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                        <img
                          src={require("../../../../assets/IfscLogo/" +
                            IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                          alt="bank"
                        />
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <div className="text-base text-black antialiased">
                          +91&nbsp;{beneficiaryAccount?.contact_no}
                        </div>
                        <div className="text-gray-500 text-xs antialiased">
                          Contact number
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                        <img src={ContactNumber} alt="Contact Number" />
                      </div>
                    </div>
                    <div className="simpleLine bg-[#A0A0A0]"></div>
                    <div className="flex flex-row justify-between py-0.5">
                      <div className="flex flex-col space-y-1 text-left w-4/6">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={beneficiaryAccount?.email_id}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <div
                            id="crt-bene-acc-email-id-firstLetter"
                            className="text-base font-medium text-black antialiased"
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {beneficiaryAccount?.email_id?.toLowerCase()}
                          </div>
                        </Tooltip>

                        <div className="text-gray-500 text-xs antialiased">
                          Email ID
                        </div>
                      </div>
                      <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                        <img src={Email} alt="Email" />
                      </div>
                    </div>
                    {beneficiaryAccount?.gstin_detail && (
                      <>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount.gstin_detail}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              GSTIN (optional)
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : null}
              {btnToggle.accountPan ? (
                <>
                  {getFreshPanStatus ? (
                    <div
                      className="pan-section-container overflow-y-auto"
                      style={{
                        width: getFreshBankStatus ? "468px" : "500px",
                        flex: "auto",
                      }}
                    >
                      {haveApproved && (
                        <>
                          <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-8 py-0.5">
                            Approved accounts
                          </p>
                          <div className="flex flex-col w-full px-8">
                            {beneLookData?.beneficiary_accounts.map(
                              (val, index) => {
                                if (val?.approval_status === "approved") {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className="group flex flex-col drop-shadow-xl pb-2 overflow-hidden"
                                      >
                                        <div
                                          className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            arrow
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                                textTransform: "capitalize",
                                              },
                                            }}
                                            title={val?.name?.toLowerCase()}
                                            placement="bottom-start"
                                          >
                                            {val?.name?.toLowerCase()}
                                          </Tooltip>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col space-y-1 text-left">
                                            <div className="text-base text-black antialiased">
                                              {formatAccountNumber(
                                                val.bank_acc_no
                                              )}
                                              <br />
                                              <span className="text-xs text-[#828282]">
                                                Bank account number
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-row justify-end w-2/6 h-4">
                                            <img
                                              src={require("../../../../assets/IfscLogo/" +
                                                IfscCodeMapper(val?.ifsc_code))}
                                              alt="bank"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="simpleLine bg-[#A0A0A0]"></div>
                                    </>
                                  );
                                }
                                return null;
                              }
                            )}
                          </div>
                        </>
                      )}
                      {havePending && (
                        <>
                          <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-8 py-0.5">
                            Pending accounts
                          </p>
                          <div className="flex flex-col w-full px-8 mt-3">
                            {beneLookData?.beneficiary_accounts
                              .filter((fil) => {
                                return fil?.id != id;
                              })
                              .map((val, index) => {
                                if (val?.approval_status === "pending") {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className="group flex flex-col drop-shadow-xl pb-2 overflow-hidden"
                                      >
                                        <div
                                          className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            arrow
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                                textTransform: "capitalize",
                                              },
                                            }}
                                            title={val?.name?.toLowerCase()}
                                            placement="bottom-start"
                                          >
                                            {val?.name?.toLowerCase()}
                                          </Tooltip>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col space-y-1 text-left">
                                            <div className="text-base text-black antialiased">
                                              {formatAccountNumber(
                                                val.bank_acc_no
                                              )}
                                              <br />
                                              <span className="text-xs text-[#828282]">
                                                Bank account number
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-row justify-end w-2/6 h-4">
                                            <img
                                              src={require("../../../../assets/IfscLogo/" +
                                                IfscCodeMapper(val?.ifsc_code))}
                                              alt="bank"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="simpleLine bg-[#A0A0A0]"></div>
                                    </>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                      <div className="w-[40%] flex flex-row justify-center">
                        <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                      </div>
                      <div className="text-white text-lg antialiased">
                        <p>
                          No associated accounts found for the PAN details
                          provided
                          <br />
                          <span className="text-xs">
                            There are no existing accounts that are associated
                            with the PAN details provided.
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {btnToggle.accountBank ? (
                <>
                  {getFreshBankStatus ? (
                    <div
                      className="pan-section-container overflow-y-auto"
                      style={{
                        width: getFreshPanStatus ? "468px" : "500px",
                        flex: "auto",
                      }}
                    >
                      {haveBankApproved && (
                        <>
                          <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-8 py-0.5">
                            Approved accounts
                          </p>
                          <div className="flex flex-col w-full px-8">
                            {benebankLookData?.beneficiary_accounts.map(
                              (val, index, array) => {
                                if (val?.approval_status === "approved") {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className="group flex flex-col drop-shadow-xl overflow-hidden"
                                      >
                                        <div
                                          className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            arrow
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                                textTransform: "capitalize",
                                              },
                                            }}
                                            title={val.name}
                                            placement="bottom-start"
                                          >
                                            {val.name
                                              ? val?.name?.toLowerCase()
                                              : ""}
                                          </Tooltip>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col space-y-1 text-left">
                                            <div className="text-base text-black antialiased">
                                              {val.pan_no}
                                              <br />
                                              <span className="text-xs text-[#828282]">
                                                PAN number
                                              </span>
                                            </div>
                                          </div>{" "}
                                          <div className="flex flex-row justify-end w-2/6 h-4">
                                            {/* <img
                                          src={require("../../../../assets/Banks/" +
                                            bankLogoMapper(val?.bank_name) +
                                            ".png")}
                                          alt="bank"
                                        /> */}
                                          </div>
                                        </div>
                                      </div>
                                      {index !== array.length - 1 && (
                                        <div className="simpleLine bg-[#A0A0A0]"></div>
                                      )}
                                    </>
                                  );
                                }
                                return null;
                              }
                            )}
                          </div>
                        </>
                      )}
                      {haveBankPending && (
                        <>
                          <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-8 py-0.5">
                            Pending accounts
                          </p>
                          <div className="flex flex-col w-full px-8 mt-3">
                            {benebankLookData?.beneficiary_accounts
                              .filter((val) => val?.id != id)
                              .map((val, index, array) => {
                                if (val?.approval_status === "pending") {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className="group flex flex-col drop-shadow-xl overflow-hidden"
                                      >
                                        <div
                                          className="w-4/5 antialiased truncate text-[#000000] text-sm font-semibold"
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            arrow
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                                textTransform: "capitalize",
                                              },
                                            }}
                                            title={val?.name}
                                            placement="bottom-start"
                                          >
                                            {val?.name?.toLowerCase()}
                                          </Tooltip>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col space-y-1 text-left">
                                            <div className="text-base text-black antialiased">
                                              {val.pan_no}
                                              <br />
                                              <span className="text-xs text-[#828282]">
                                                PAN number
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-row justify-end w-2/6 h-4">
                                            {/* <img
                                          src={require("../../../../assets/Banks/" +
                                            bankLogoMapper(val?.bank_name) +
                                            ".png")}
                                          alt="bank"
                                        /> */}
                                          </div>
                                        </div>
                                      </div>
                                      {index !== array.length - 1 && (
                                        <>
                                          <div className="simpleLine bg-[#A0A0A0]"></div>
                                        </>
                                      )}
                                    </>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                      <div className="w-[40%] flex flex-row justify-center">
                        <img className="max-w-[145%] pt-5" alt="NoDataImg" />
                      </div>
                      <div className="text-white text-lg antialiased">
                        <p>
                          No associated accounts found for the PAN details
                          provided
                          <br />
                          <span className="text-xs">
                            There are no existing accounts that are associated
                            with the PAN details provided.
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              <>
                <div
                  className="success-button-conatiner"
                  style={{
                    display:
                      services?.find(
                        (service) =>
                          service === "BAAccount" || service === "Admin"
                      ) !== undefined &&
                      !btnToggle.accountPan &&
                      !btnToggle.accountBank &&
                      !getCheckerBtnStatus
                        ? ""
                        : "none",
                  }}
                >
                  <div
                    className={`${
                      getCheckerBtnStatus
                        ? "pay-bene-box2-decline-disabled"
                        : "pay-bene-box2-decline"
                    }`}
                    onClick={
                      services?.find(
                        (service) =>
                          service === "BAAccount" || service === "Admin"
                      ) !== undefined && !getCheckerBtnStatus
                        ? () => handleDeclinedDetials(id)
                        : null
                    }
                  >
                    <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                      <div className="decline-image-div flex justify-center">
                        <Box className="flex justify-center w-10">
                          <img
                            src={declineImage}
                            alt="document"
                            className="decline-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          Reject
                        </Box>
                      </div>
                    </Box>
                  </div>
                  <div
                    className={`${
                      getCheckerBtnStatus
                        ? "pay-bene-box2-Approve-disabled"
                        : "pay-bene-box2-Approve"
                    }`}
                    onClick={
                      services?.find(
                        (service) =>
                          service === "BAAccount" || service === "Admin"
                      ) !== undefined && !getCheckerBtnStatus
                        ? () => handleApprovalDetails(id)
                        : null
                    }
                  >
                    <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                      <div className="approve-image-div flex justify-center">
                        <Box className="flex justify-center w-10">
                          <img
                            src={checkmark}
                            alt="document"
                            className="approve-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          Approve
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>
              </>
              <div
                className="w-full divide-x-2 py-7 text-white rounded-4xl bg-[#55985C] flex justify-evenly items-center gap-0 footer-div-navigate"
                style={{
                  bottom: "-2px",
                }}
              >
                <div
                  className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 cursor-pointer items-center justify-center text-center"
                  onClick={createAccountHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"70%"}
                      height={"50%"}
                      src={createAccount}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Create
                    <br />
                    new account
                  </p>
                </div>
                <div
                  className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 cursor-pointer items-center justify-center text-center border-gray-300"
                  onClick={() => {
                    if (
                      services?.find(
                        (service) =>
                          service === "CBeneficiaryTxn" || service === "Admin"
                      ) !== undefined
                    ) {
                      createTxn();
                    }
                  }}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"70%"}
                      height={"50%"}
                      src={createTransaction}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Create
                    <br />
                    transaction
                  </p>
                </div>
                <div
                  className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 cursor-pointer items-center justify-center text-center border-gray-300"
                  onClick={viewDetailsHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"70%"}
                      height={"50%"}
                      src={viewDetails}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    View
                    <br />
                    details
                  </p>
                </div>
                <div
                  className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 cursor-pointer items-center justify-center text-center border-gray-300"
                  onClick={dashboardHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"70%"}
                      height={"50%"}
                      src={dashboard}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Go to <br /> dashboard
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneCreateSuccess;
