import React from "react";
import "./index.css";
import SearchBar from "../../../_utils/SearchBar";
import SelectBank from "../../../_utils/SelectBank";
import ArrowLeft from "../../../../assets/CreateTransactionIcons/arrowleft.png";
import ArrowRight from "../../../../assets/CreateTransactionIcons/arrowright.png";
import { tooltipClasses, Tooltip, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import Loader from "../../../../assets/CreateTransactionIcons/inputloder.gif";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";

const NonActiveBanking = ({
  filterPayload,
  selectBank,
  setFilterPayload,
  setSelectBank,
  smallSearch,
  setSmallSearch,
  inputRef1,
  removeAccNo,
  beneOwnerData,
  beneOwnerLoading,
  setRefreshAnimationId,
}) => {
  const handlePagination = (e) => {
    e.preventDefault();
    setFilterPayload({
      ...filterPayload,
      currentPageText: e.target.value,
    });
    if (
      e.target.value !== "" &&
      e.target.value !== 0 &&
      e.target.value !== "0"
    ) {
      handlepage(e.target.value);
    }
  };

  const handlepage = debounce((page) => {
    if (page <= beneOwnerData?.title?.total_pages && page !== "00") {
      let value = page.length > 1 ? page.substring(1) : page;
      if (filterPayload?.currentPage != value) {
        setFilterPayload({
          ...filterPayload,
          currentPageText: page,
          currentPage: Number(value),
        });
      }
    } else if (page > beneOwnerData?.title?.total_pages) {
      setFilterPayload({
        ...filterPayload,
        currentPageText: "0" + beneOwnerData?.title?.total_pages,
        currentPage: beneOwnerData?.title?.total_pages,
      });
    } else {
      setFilterPayload({
        ...filterPayload,
        currentPageText: "01",
        currentPage: 1,
      });
    }
  }, 100);

  const nextPage = () => {
    let val = Number(filterPayload?.currentPageText) + 1;
    setFilterPayload({
      ...filterPayload,
      currentPage: filterPayload?.currentPage + 1,
      currentPageText: val > 9 ? val : "0" + val,
    });
  };

  const prevPage = () => {
    let val = Number(filterPayload?.currentPageText) - 1;
    setFilterPayload({
      ...filterPayload,
      currentPage: filterPayload?.currentPage - 1,
      currentPageText: val > 9 ? val : "0" + val,
    });
  };

  const handleBlur = () => {
    if (
      filterPayload?.currentPageText.length === 0 ||
      filterPayload?.currentPageText === "0"
    ) {
      setFilterPayload({
        ...filterPayload,
        currentPageText:
          filterPayload?.currentPage > 1 ? filterPayload?.currentPage : "01",
      });
    }
  };

  return (
    <div className="active_main_div_diff">
      <div className="container_page_search">
        <div className="owner_popup_sort">
          <SelectBank
            selectBank={selectBank}
            filterPayload={filterPayload}
            setFilterPayload={setFilterPayload}
            setSelectBank={setSelectBank}
            option={beneOwnerData?.summary?.available_banks || []}
          />
        </div>

        <div className="page_search_mix">
          <div
            className="pagination_owner_acc"
            style={{ display: smallSearch ? "none" : "flex" }}
          >
            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                  },
                },
              }}
              title="Prev"
            >
              <div
                onClick={() => {
                  if (
                    filterPayload?.currentPage !== 1 ||
                    filterPayload?.currentPage > 1
                  ) {
                    prevPage();
                  }
                }}
                style={{
                  width: "30px !important",
                  paddingRight: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={ArrowLeft}
                  alt=""
                  style={{
                    opacity: filterPayload?.currentPage === 1 ? 0.5 : 1,
                  }}
                  className="arrow-images"
                />
              </div>
            </Tooltip>
            <TextField
              size="small"
              type="text"
              id="outlined-basic"
              variant="standard"
              onBlur={handleBlur}
              value={filterPayload?.currentPageText}
              defaultValue={"01"}
              onChange={handlePagination}
              InputLabelProps={{
                style: { color: "#fff", borderColor: "#fff" },
              }}
              InputProps={{
                style: {
                  font: "normal normal 600 14px/20px Open Sans",
                  padding: "2px 7px",
                },
              }}
              color="info"
              sx={{
                borderColor: "#fff !important",
                backgroundColor: "white",
                color: "#fff",
                font: "normal normal 600 12px/17px Open Sans",
                width: "30px !important",
                height: "30px !important",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                    color: "#fff",
                  },
                },
              }}
            />
            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                  },
                },
              }}
              title="Next "
            >
              <div
                onClick={() => {
                  if (
                    filterPayload?.currentPage <
                    beneOwnerData?.title?.total_pages
                  ) {
                    nextPage();
                  }
                }}
                style={{
                  width: "30px !important",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={ArrowRight}
                  style={{
                    opacity:
                      filterPayload?.currentPage >=
                        beneOwnerData?.title?.total_pages ||
                      beneOwnerData?.title?.total_pages === undefined
                        ? 0.5
                        : 1,
                  }}
                  alt=""
                  className="arrow-images"
                />
              </div>
            </Tooltip>
          </div>
          <SearchBar
            smallSearch={smallSearch}
            setSmallSearch={setSmallSearch}
            inputRef1={inputRef1}
            removeAccNo={removeAccNo}
            setFilterPayload={setFilterPayload}
            filterPayload={filterPayload}
            setRefreshAnimationId={setRefreshAnimationId}
          />
        </div>
      </div>

      <div className="data_bank_acc_no">
        {!beneOwnerLoading && beneOwnerData?.total_count === 0 ? (
          <div className=" w-full h-[400px] flex flex-col gap-5  justify-center items-center">
            <div className="w-[300px]">
              <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
            </div>

            <div className="flex flex-col gap-1 items-center justify-center">
              <h5 className=" text-center text-white text-opacity-100 text-[16px] leading-[22px] font-sans">
                No active owner accounts found
              </h5>
              <p className="w-[407px] h-[17px] text-left text-[12px] leading-[17px] font-sans text-[#DADADA]">
                There are no existing accounts that can be used to settle this
                transaction
              </p>
            </div>
          </div>
        ) : !beneOwnerLoading && beneOwnerData ? (
          beneOwnerData?.owner_accounts?.map((val, i) => {
            return (
              <Button
                className="data_bank_acc_no_inner"
                style={{ opacity: "0.5" }}
                disabled={true}
                key={i}
              >
                <div
                  className="bank_display_div"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="bank_display_div_white">
                    <img
                      src={require("../../../../assets/IfscLogo/" +
                        IfscCodeMapper(val?.ifsc_code))}
                      alt="bank"
                      className="detail-bank-logo"
                      style={{ width: "50px", height: "13px" }}
                    />
                  </div>
                  <div className="bank_display_div_no">
                    <p className="bank_display_div_no_1">
                      {val?.bank_acc_no.toString().substring(0, 4)} ••••{" "}
                      {val?.bank_acc_no
                        .toString()
                        .substring(val?.bank_acc_no?.length - 4)}
                    </p>
                    <p className="bank_display_div_no_2">Account number</p>
                  </div>
                </div>
                <div
                  className="bank_amt_div"
                  style={{
                    visibility: "hidden",
                  }}
                ></div>
              </Button>
            );
          })
        ) : (
          <>
            {beneOwnerLoading && (
              <div className="loading_owner">
                <img src={Loader} alt="loader" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NonActiveBanking;
